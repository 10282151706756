import React from "react";
import { Link } from "react-router-dom";
import Container from "../Reusable/Container";
import { Button } from "@mui/material";
import { motion } from "framer-motion";

import { fadeIn, fadeInCenter } from "../Reusable/Variants";

const OurServices = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const servicesData = [
    {
      title: "Air Freight",
      description:
        "Fast, reliable air cargo solutions. Swift transportation, seamless customs clearance, and global delivery network for urgent shipments.",
      imageSrc: "../assets/airplane.png",
    },
    {
      title: "Ocean Freight",
      description:
        "Cost-effective sea cargo services. Efficient handling, smooth documentation, and reliable delivery worldwide for your shipping needs.",
      imageSrc: "../assets/shipping.png",
    },
    {
      title: "Logistics Consultancy",
      description:
        "Strategic guidance for optimizing supply chain. Expert advice, tailored solutions, and actionable recommendations to drive growth and efficiency.",
      imageSrc: "../assets/logistic.png",
    },
    {
      title: "Door to Door Delivery",
      description:
        "Seamless shipping solutions. From pickup to final delivery, efficient service, real-time tracking, and proactive communication for smooth logistics.",
      imageSrc: "../assets/truck.png",
    },
    {
      title: "Custom Clearance",
      description:
        "Cost-effective sea cargo services. Efficient handling, smooth documentation, and reliable delivery worldwide for your shipping needs.",
      imageSrc: "../assets/custom.png",
    },
    {
      title: "Warehouse and Distribution Service",
      description:
        "Cost-effective sea cargo services. Efficient handling, smooth documentation, and reliable delivery worldwide for your shipping needs.",
      imageSrc: "../assets/warehouse.png",
    },
    {
      title: "Port Handlnig",
      description:
        "Cost-effective sea cargo services. Efficient handling, smooth documentation, and reliable delivery worldwide for your shipping needs.",
      imageSrc: "../assets/port.png",
    },
    {
      title: "Special Cargo Handling",
      description:
        "Cost-effective sea cargo services. Efficient handling, smooth documentation, and reliable delivery worldwide for your shipping needs.",
      imageSrc: "../assets/specialcontainer.png",
    },
  ];

  return (
    <div className="bg-gray-100">
      <Container>
        <div
          className="py-24 mx-auto flex flex-col lg:flex-row gap-6 justify-center"
          id="services"
        >
          <motion.div
            variants={fadeInCenter}
            initial="hidden"
            whileInView={"show"}
            className="mx-auto grid gap-4 md:gap-16 md:grid-cols-2 lg:grid-cols-3 py-8"
          >
            {servicesData.map((service, index) => (
              <div
                key={index}
                className="flex flex-col justify-center items-start"
              >
                <Link
                  to={`/services/${service.title
                    .toLowerCase()
                    .replace(/\s+/g, "-")}`}
                  onClick={scrollToTop}
                >
                  <img className="w-12 h-12" src={service.imageSrc} />
                  <h1 className="text-lg pt-2 pb-2 font-bold text-[#247C8F] max-w-2xl">
                    {service.title}
                  </h1>
                  <p className="text-sm text-black max-w-xs text-start">
                    {service.description}
                  </p>
                  <div className="py-5">
                    <Button variant="text">Learn more</Button>
                  </div>
                </Link>
              </div>
            ))}
          </motion.div>
        </div>
      </Container>
    </div>
  );
};

export default OurServices;
