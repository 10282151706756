import React from "react";
import Hero from "../Components/Homepage/Hero";
import Results from "../Components/Homepage/Results";
import About from "../Components/Homepage/About";
import Section from "../Components/Homepage/Section";
import Section2 from "../Components/Homepage/Section2";
import Services from "../Components/Homepage/Services";
import Reasons from "../Components/Homepage/Reasons";
import Slider from "../Components/Homepage/Slider";


const Home = () => {
  return (
    <div>
      <Hero />
      <About />
      <Results />

      {/* <Section /> */}
      <Section2 />
      <Services />
      <Slider />
      {/* <Reasons /> */}
    
    </div>
  );
};

export default Home;
