import React from "react";
import { useParams } from "react-router-dom";
import AirFreight from "./AirFreight";
import LogisticsConsultancy from "./LogisticsConsultancy";
import DoortoDoorDelivery from "./DoortoDoorDelivery";

import OceanFreight from "./OceanFreight";
import WarehouseDistribution from "./WarehouseandDistribution";
import SpecialCargo from "./SpecialCargoHandling";
import PortHandling from "./PortHandling";
import CustomClearance from "./CustomClearance";
// import WarehouseDistribution from "./Warehouse-and-Distribution";
const ServiceDetail = () => {
  const { service } = useParams();

  return (
    <div>
      {service === "air-freight" && (
        <div>
          <AirFreight />
        </div>
      )}
      {service === "logistics-consultancy" && (
        <div>
          <LogisticsConsultancy />
        </div>
      )}
      {service === "door-to-door-delivery" && (
        <div>
          <DoortoDoorDelivery />
        </div>
      )}
      {service === "ocean-freight" && (
        <div>
          <OceanFreight />
        </div>
      )}
      {service === "warehouse-and-distribution-service" && (
        <div>
          <WarehouseDistribution />
        </div>
      )}
      {service === "special-cargo-handling" && (
        <div>
          <SpecialCargo />
        </div>
      )}
      {service === "custom-clearance" && (
        <div>
          <CustomClearance />
        </div>
      )}
      {service === "port-handling" && (
        <div>
          <PortHandling />
        </div>
      )}
    </div>
  );
};

export default ServiceDetail;
