import React from "react";
import Container from "../Reusable/Container";
// import servi from "../../assets/servi.png";
import { Button } from "@mui/material";

import { motion } from "framer-motion";

import { fadeIn } from "../Reusable/Variants";

const Services = () => {
  return (
    <Container>
      <div
        className="py-24  mx-auto flex flex-col lg:flex-row  gap-6 justify-center "
        id="services"
      >
        <div className=" flex justify-center items-center">
          <img className=" p-12 rounded-md" src="./assets/Services.jfif" alt="Logo" />
        </div>
        <div className=" max-w-[1240px] ">
          <div className=" flex justify-center items-center">
            <h1 className=" text-3xl pt-2 pb-16 font-extrabold text-[#247C8F] max-w-2xl  ">
              Features And Services
            </h1>
          </div>
          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className="flex flex-col lg:flex-row justify-center gap-30 pb-12"
          >
            <div className=" flex flex-col justify-center items-center">
              <img
                className="w-1/6 h-1/4"
                src="./assets/airplane.png"
                alt="Placeholder Image"
              />
              <h1 className=" text-xl pt-2 pb-2 font-bold text-[#247C8F] max-w-2xl  ">
                Air Freight
              </h1>
              <p className="text-sm px-4 text-black max-w-xl text-start  ">
                We deliver our service at Five Rights/Place, product, quantity,
                time and cost/. We offer a complete range of air logistics
                services. Our experienced team works
              </p>
              <div className="py-5">
                <Button href="/services" variant="text">
                  Learn more
                </Button>
              </div>
            </div>

            <div className=" flex flex-col justify-center items-center">
              <img
                className="w-1/6 h-1/4"
                src="./assets/logistic.png"
                alt="Placeholder Image"
              />
              <h1 className=" text-xl pt-2 pb-2 font-bold text-[#247C8F] max-w-2xl  ">
                Logistics Consultancy
              </h1>
              <p className="text-sm px-4 text-black max-w-xl text-start  ">
                Consultancy services to the whole supply chain management
                including professional road and bridge survey. We give advice
                for clients based on their requirements and our
              </p>
              <div className="py-5">
                <Button href="/services" variant="text">
                  Learn more
                </Button>
              </div>
            </div>
          </motion.div>

          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className="flex flex-col lg:flex-row justify-center gap-30 pb-12"
          >
            <div className=" flex flex-col justify-center items-center">
              <img
                className="w-1/6 h-1/4"
                src="./assets/truck.png"
                alt="Placeholder Image"
              />
              <h1 className=" text-xl pt-2 pb-2 font-bold text-[#247C8F] max-w-2xl  ">
                Door to Door Delivery
              </h1>
              <p className="text-sm px-4 text-black max-w-xl text-start  ">
                Seamless shipping solutions. From pickup to final delivery,
                efficient service, real-time tracking, and proactive
                communication for smooth logistics.
              </p>
              <div className="py-5">
                <Button href="/services" variant="text">
                  Learn more
                </Button>
              </div>
            </div>

            <div className=" flex flex-col justify-center items-center">
              <img
                className="w-1/6 h-1/4"
                src="./assets/shipping.png"
                alt="Placeholder Image"
              />
              <h1 className=" text-xl pt-2 pb-2 font-bold text-[#247C8F] max-w-2xl  ">
                Sea Freight
              </h1>
              <p className="text-sm px-4 text-black max-w-xl text-start  ">
                Sea Freight Details • End to end solution • In-house customs
                brokerage • Heavy & over gauge cargo handling • Efficient
                transport service with own
              </p>
              <div className="py-5">
                <Button href="/services" variant="text">
                  Learn more
                </Button>
              </div>
            </div>
          </motion.div>
          {/* <div
            class="flex flex-col items-center justify-center animate-bounce cursor-pointer"
            onclick="window.location.href='/services';"
          >
            <p class="text-md text-[#247C8F]">See More</p>
            <img
              class=" w-6 h-auto"
              src="./assets/down.png"
              alt="Placeholder Image"
            />
          </div> */}
          <a
            href="/services"
            class="flex flex-col items-center justify-center animate-bounce cursor-pointer"
          >
            <p class="text-md text-[#247C8F]">See More</p>
            <img
              class="w-6 h-auto"
              src="./assets/down.png"
              alt="Placeholder Image"
            />
          </a>
        </div>
      </div>
    </Container>
  );
};

export default Services;
