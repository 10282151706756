import { createSlice } from "@reduxjs/toolkit";
import {
  registerUser,
  userLogin,
  fetchShipment,
  fetchShipmentByTrackingId,
  requestQuote,
  getAllQuotes,
  fetchShipmentByContainerId,
} from "../actions/auth.action";

// initialize userToken from local storage
const userToken = localStorage.getItem("userToken") || null;

const initialState = {
  loading: false,
  userInfo: null,
  userToken,
  error: null,
  success: false,
  shipmentInfo: null, // Add shipmentInfo property to initial state
  shipmenttrackInfo: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("userToken"); // delete token from storage
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
    },
    setCredentials: (state, { payload }) => {
      state.userInfo = payload;
    },
  },
  extraReducers: (builder) => {
    // login user
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload;
        state.userToken = payload.userToken;
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    // register user
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    // fetch shipment
    builder
      .addCase(fetchShipment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShipment.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.shipmentInfo = payload; // Set shipment information in the state
      })
      .addCase(fetchShipment.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload.message; // Handle error message
        state.errorStatus = payload.status; // Handle error status
      });
    builder
      .addCase(fetchShipmentByTrackingId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShipmentByTrackingId.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.shipmenttrackInfo = payload; // Handle the fulfillment action according to your application's logic
      })
      .addCase(fetchShipmentByTrackingId.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
      builder
    
        // Handling fetchShipmentByContainerId
        .addCase(fetchShipmentByContainerId.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchShipmentByContainerId.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.shipmentcrackInfo = payload; // Handle the fulfillment action according to your application's logic
        })
        .addCase(fetchShipmentByContainerId.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload;
        });
        builder
          .addCase(requestQuote.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(requestQuote.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.requestedQuote = payload;
            // Handle fulfillment action for request quote if needed
          })
          .addCase(requestQuote.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
          });

        // get all quotes
        builder
          .addCase(getAllQuotes.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(getAllQuotes.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.allQuotes = payload;
            // Handle fulfillment action for get all quotes if needed
          })
          .addCase(getAllQuotes.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
          });


  },
});

export const { logout, setCredentials } = authSlice.actions;

export default authSlice.reducer;
