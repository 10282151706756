import React from "react";

const Section2 = () => {
  const backgroundImage = {
    backgroundImage: `url('/service.png')`,
    backgroundSize: "cover", // Add other background properties as needed
    // ... other background properties
  };

  return (
    <div
      className="  h-[75vh] lg:h-[40vh] flex justify-center items-center"
      style={backgroundImage}
    >
      <div className=" py-12 max-w-[1240px] mx-auto grid lg:grid-cols-3 gap-5">
        <div className="">
          <h1 className=" text-4xl pt-12 mt-12  pb-2 px-4 font-extrabold text-white max-w-2xl  ">
           Special Services
          </h1>
        </div>
        <div className=" px-20 pt-10 pb-2">
          <h1 className=" text-xl pt-2 pb-2 px-1 font-extrabold text-white  max-w-2xl  ">
            Sea/Air Freight Service
          </h1>
          <p className="text-sm  text-white max-w-xl text-start  ">
            Input Africa provides sea/Air combination shipment if and when
            required. The inter-modal transport system has a great advantage of
            cost while meeting the deadline.
          </p>
        </div>
        <div className="  px-20 pt-10 pb-2">
          <h1 className=" text-xl pt-2 pb-2 px-1 font-extrabold text-white max-w-2xl  ">
            Special Cargo Handling
          </h1>
          <p className="text-sm  text-white max-w-xl text-start  ">
            Input Africa has extensive experience and knowledge in handling
            special cargos that are sensitive natured, one piece of heavy volume
            and size etc. We have expertise
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section2;
