import React from "react";
import Container from "../Reusable/Container";
// import Aboutu from "./assets/About.png";
import { Button } from "@mui/material";

import { motion } from "framer-motion";
import { fadeIn } from "../Reusable/Variants";

const About = () => {
  return (
    <div style={{ marginTop: "100rm" }}>
      <div className="  max-w-[1240px] mx-auto grid md:grid-cols-2 gap-10 pb-24 md:py-24">
        <motion.div
          variants={fadeIn("right", 0.5)}
          initial="hidden"
          whileInView={"show"}
          className=" "
        >
          <h1 className=" text-3xl pt-32 pb-2 px-4 font-bold text-[#247C8F] max-w-2xl  ">
            Welcome
          </h1>
          <p className="text-sm pt-2 py-6 text-[#919B9D] max-w-2xl  text-start mx-4 ">
            Founded in 2008, Input Africa leads the charge in delivering
            reliable logistics solutions powered by state-of-the-art technology.
            Partnering with top-tier providers worldwide, we elevate Ethiopia as
            a premier logistics hub on both African and global stages. As
            pioneers in meeting national logistics demand, we prioritize the
            satisfaction of our stakeholders, enhancing profitability and
            fostering a culture of continuous learning for our dedicated team.
            At Input Africa, we're proud to embrace the latest technology,
            including IFFS, ERP, and direct access to Ethiopian Customs,
            ensuring efficiency and accuracy at every step. Based in Addis
            Ababa, with a global footprint, our expert team ensures your cargo's
            journey is handled with the utmost care from origin to destination.
            Experience the Input Africa difference today!
          </p>
          <Button variant="text" size="large">
            Learn More
          </Button>
        </motion.div>
        <motion.div
          variants={fadeIn("left", 0.5)}
          initial="hidden"
          whileInView={"show"}
          className=" flex justify-center w-auto p-8 md:p-0"
        >
          <img className=" w-full rounded-md" src="./assets/Companyrec.jpg" alt="Logo" />
        </motion.div>
      </div>
    </div>
  );
};

 export default About;
