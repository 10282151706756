import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchShipmentByTrackingId } from "../../store/actions/auth.action"; // Update the import path
import { AiOutlineClose } from "react-icons/ai"; // Import close icon
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

const ShipmentTracker = () => {
  const [isTrackingModalOpen, setIsTrackingModalOpen] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [terror, setTerror] = useState("");
//   const [shipmenttrackInfo, setShipmenttrackInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
 const shipmenttrackInfo = useSelector(
   (state) => state.auth.shipmenttrackInfo?.bol
 );
  const handleCloseModal = () => {
    setIsTrackingModalOpen(false);
    setTrackingNumber("");
  };

    const handleTrackSubmit = () => {
      if (trackingNumber.trim() === "") {
        setTerror("Please enter a tracking number");
        return;
      }
      dispatch(fetchShipmentByTrackingId(trackingNumber))
        .then(() => {
          setIsTrackingModalOpen(true);
          console.log(shipmenttrackInfo);
          // Handle successful fetching of shipment details
        })
        .catch((error) => {
          console.error("Failed to fetch shipment details:", error);
        });
      setTerror("");
    };
  const formattedDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };
  const isTrackingNumberEmpty = trackingNumber.trim() === "";

  return (
    <div className="w-full md:w-1/2 mt-3">
      <div className=" mt-4">
        <div className="flex flex-col">
          <input
            type="text"
            placeholder="Enter Tracking Number"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            className="w-full h-12 mb-4 border border-gray-300 text-black rounded-lg px-4 focus:outline-none focus:border-[#247C8F]"
          />
          {terror && <p className="text-red-500">{terror}</p>}
          <button
            className="bg-[#247C8F] hover:bg-[#005B6E] text-white font-bold py-2 px-7 rounded-lg self-start mt-3 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleTrackSubmit}
            disabled={isTrackingNumberEmpty}
          >
            Track
          </button>
        </div>
      </div>

      <Modal open={isTrackingModalOpen} onClose={handleCloseModal}>
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-10 w-1/2 max-w-screen max-h-screen rounded-lg shadow-lg flex flex-col justify-between">
            <div className="flex justify-end">
              <button
                onClick={handleCloseModal}
                className="text-gray-500 hover:text-gray-800 focus:outline-none"
              >
                <AiOutlineClose className="h-6 w-6" />
              </button>
            </div>
            <div className="flex justify-center mb-4">
              <h1 className="text-2xl font-semibold mt-2">Shipment Details</h1>
            </div>
            {shipmenttrackInfo ? (
              <div className="overflow-auto flex-grow">
                {/* <p>
                  <strong>Company Name:</strong>{" "}
                  {shipmenttrackInfo.company.companyName}
                </p> */}
                <p>
                  <strong>Declaration Number:</strong>{" "}
                  {shipmenttrackInfo.declarationNumber}
                </p>
                {/* <p>
                  <strong>Number of Containers:</strong>{" "}
                  {shipmenttrackInfo.containers.length}
                </p> */}
                <p>
                  <strong>Created On:</strong>{" "}
                  {formattedDate(shipmenttrackInfo.createdAt)}
                </p>
                <p>
                  <strong>Origin:</strong> {shipmenttrackInfo.route.originName}
                </p>
                <p>
                  <strong>Destination:</strong>{" "}
                  {shipmenttrackInfo.route.destinationName}
                </p>
                <p>
                  <strong>Total Distance:</strong>{" "}
                  {shipmenttrackInfo.route.totalDistance}
                </p>
                <p>
                  <strong>Estimated Delivery Date:</strong>{" "}
                  {formattedDate(shipmenttrackInfo.estimatedArrivalDate)}
                </p>
                <p>
                  <strong>Status:</strong> {shipmenttrackInfo.status}
                </p>
              </div>
            ) : loading ? (
              <div>Loading...</div>
            ) : error ? (
              <div>Error: {error}</div>
            ) : (
              <div className="flex justify-center items-center">
                <div className="text-center">
                  No shipment information available
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <style jsx>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .modal-content {
          background: white;
          padding: 20px;
          border-radius: 8px;
          max-width: 500px;
          width: 100%;
          max-height: 90vh;
          overflow-y: auto;
        }
      `}</style>
    </div>
  );
};

const Modal = ({ open, onClose, children }) => {
  if (!open) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content">{children}</div>
    </div>,
    document.getElementById("portal")
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ShipmentTracker;
