import React, { useState } from "react";
import { Button, TextField, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import Heroimg from "../../assets/Hero.png";

import BackgroundVideo from "../Reusable/BackgroundVideo";

import { AiOutlineClose } from "react-icons/ai";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import {
  fetchShipmentByContainerId,
  fetchShipmentByTrackingId,
  requestQuote,
} from "../../store/actions/auth.action";
const Hero = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [isTrackingInputVisible, setIsTrackingInputVisible] = useState(false);
  const [isTrackingModalOpen, setIsTrackingModalOpen] = useState(false);
  const [isQuotationModalOpen, setIsQuotationModalOpen] = useState(false);
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const [terror, setTerror] = useState("");
  const [rloading, setRloading] = useState(false);
  const [rerror, setRerror] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const shipmenttrackInfo = useSelector(
    (state) => state.auth.shipmenttrackInfo?.bol
  );
  const shipmentcrackInfo = useSelector(
    (state) => state.auth.shipmentcrackInfo?.shipment
  );
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    originCountry: "",
    destinationCountry: "",
    comment: "",
  });
  const [phone, setPhone] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [trackingType, setTrackingType] = useState("BOL");
  const [isTrackingNumberEmpty, setIsTrackingNumberEmpty] = useState(true);
  const handleTrackSubmit = () => {
    if (trackingType === "BOL") {
      if (trackingNumber.trim() === "") {
        setTerror("Please enter a tracking number");
        return;
      }
      dispatch(fetchShipmentByTrackingId(trackingNumber))
        .then(() => {
          setIsTrackingModalOpen(true);
          console.log(shipmenttrackInfo);
          // Handle successful fetching of shipment details
        })
        .catch((error) => {
          console.error("Failed to fetch shipment details:", error);
        });
      setTerror("");
      console.log("Tracking BOL:", trackingNumber);
    } else if (trackingType === "Container") {
      // Handle Container tracking submit
      if (trackingNumber.trim() === "") {
        setTerror("Please enter a tracking number");
        return;
      }
      dispatch(fetchShipmentByContainerId(trackingNumber))
        .then(() => {
          setIsTrackingModalOpen(true);
          console.log(shipmentcrackInfo);
          // Handle successful fetching of shipment details
        })
        .catch((error) => {
          console.error("Failed to fetch shipment details:", error);
        });
      setTerror("");
      console.log("Tracking Container:", trackingNumber);
    }
  };
  const handleTinputChange = (e) => {
    setTrackingNumber(e.target.value);
    setIsTrackingNumberEmpty(e.target.value.trim() === "");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: value,
    }));
  };

  const placeholder = "./assets/cargoship.jpg";
  const videoSource =
    "https://res.cloudinary.com/dflcrqvjj/video/upload/v1715596380/Drone_Shot_of_Cargo_Containers_Terminal_Ships_at_Dock_Port._Stock_Videos_of_Logistics_Export_Import_3_vrdn5x.mp4";
  const scrollToBottom = () => {
    const bottomEle = document.querySelector("#video-bottom");
    bottomEle.scrollIntoView({ behavior: "smooth" });
  };

  // Function to handle submission of quotation request form
  const handleQuotationSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    setFormSubmitted(true); // Update formSubmitted state when form is submitted
    const phoneRegex = /^\+\d{1,3}\d{9}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Validation logic
    if (
      formData.fullName.trim() === "" ||
      formData.email.trim() === "" ||
      formData.phone.trim() === "" ||
      formData.originCountry.trim() === "" ||
      formData.destinationCountry.trim() === "" ||
      !/^[a-zA-Z\s]*$/.test(formData.fullName) ||
      !phoneRegex.test(formData.phone) || // Validate phone number format
      !emailRegex.test(formData.email) // Validate full name (only characters)
    ) {
      let errorMessage = "";

      // Update error message for full name
      if (formData.fullName.trim() === "") {
        errorMessage += " Full name is required.";
      } else if (!/^[a-zA-Z\s]*$/.test(formData.fullName)) {
        errorMessage += " Full name should only contain characters.";
      }
      if (formData.phone.trim() === "") {
        errorMessage += " Phone number is required.";
      } else if (!phoneRegex.test(formData.phone)) {
        errorMessage += " Please enter a valid phone number.";
      }
      // Update error message for phone number
      if (formData.email.trim() === "") {
        errorMessage += " Email is required.";
      } else if (!emailRegex.test(formData.email)) {
        errorMessage += " Please enter a valid email address.";
      }

      setRerror(errorMessage);
      return;
    }

    // Reset error state
    setRerror(null);
    setRloading(true);

    // Dispatch requestQuote action
    dispatch(requestQuote(formData))
      .then(() => {
        // Reset form data
        setFormData({
          fullName: "",
          email: "",
          phone: "",
          originCountry: "",
          destinationCountry: "",
          comment: "",
        });
        // Display success modal

        // Handle successful request quote action
        setRloading(false);
        console.log("Quotation requested successfully!");
      })
      .catch((error) => {
        // Handle error
        setRloading(false);
        if (error.response && error.response.status === 400) {
          setRerror("Error: Bad Request. Please check your input.");
        } else {
          setRerror(error.message || "Failed to request quotation");
        }
      });

    setIsQuotationModalOpen(false);
    setSuccessModalOpen(true);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };
  const handleCloseModal = () => {
    setIsTrackingModalOpen(false);
    setTrackingNumber("");
  };
  // const handleTrackSubmit = () => {
  //   if (trackingNumber.trim() === "") {
  //     setTerror("Please enter a tracking number");
  //     return;
  //   }
  //   dispatch(fetchShipmentByTrackingId(trackingNumber))
  //     .then(() => {
  //       setIsTrackingModalOpen(true);
  //       console.log(shipmenttrackInfo);
  //       // Handle successful fetching of shipment details
  //     })
  //     .catch((error) => {
  //       console.error("Failed to fetch shipment details:", error);
  //     });
  //   setTerror("");
  // };
  // const isTrackingNumberEmpty = trackingNumber.trim() === "";
  const handleQuotationCloseModal = () => {
    setIsQuotationModalOpen(false);
  };
  const handleQuotationModalOpen = () => {
    setIsQuotationModalOpen(true);
    setRerror(null); // Reset the error state
    setFormSubmitted(false);
  };

  const formattedDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };
  if (loading) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
        <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-[#247C8F]"></div>
      </div>
    );
  }
  // if (error) {
  //   return(
  //   <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
  //     <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-[#247C8F]">
  //     Error: {error}</div>
  //   </div>);
  // }
  const card = (
    <div class="max-w-6xl w-full mx-auto md:w-3/4 md:p-7 rounded-lg md:ml-30 md:mt-40 sm:mb-52">
      <div class="flex flex-col md:flex-row bg-white bg-opacity-50 rounded-lg shadow-xl overflow-hidden">
        {/* <div class="w-full md:w-1/2 border-b md:border-r border-gray-300">
            <div class="p-6">
              <h2 class="text-xl font-semibold text-gray-800 mb-4">
                Track Shipment
              </h2>
              <div class="flex flex-col">
                <input
                  type="text"
                  placeholder="Enter Tracking Number"
                  value={trackingNumber}
                  onChange={(e) => setTrackingNumber(e.target.value)}
                  class="w-full h-12 mb-4 border border-gray-300 rounded-lg px-4 focus:outline-none focus:border-[#247C8F]"
                />
                {terror && <p class="text-red-500">{terror}</p>}
                <button
                  class="bg-[#247C8F] hover:bg-[#005B6E] text-white font-bold py-2 px-7 rounded-lg self-start mt-3 disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={handleTrackSubmit}
                  disabled={isTrackingNumberEmpty}
                >
                  Track
                </button>
              </div>
            </div>
          </div> */}
        <div class="w-full md:w-1/2 border-b md:border-r border-gray-300">
          <div class="p-6">
            <h2 class="text-xl font-semibold text-gray-800 mb-4">
              Track Shipment
            </h2>
            <div class="flex flex-col">
              <div class="flex items-center mb-4">
                <select
                  value={trackingType}
                  onChange={(e) => setTrackingType(e.target.value)}
                  class="mr-4 h-12 border border-gray-300 rounded-lg px-4 focus:outline-none focus:border-[#247C8F]"
                >
                  <option value="BOL">BOL</option>
                  <option value="Container">Container</option>
                </select>
                <input
                  type="text"
                  placeholder="Enter Tracking Number"
                  value={trackingNumber}
                  onChange={handleTinputChange}
                  class="w-full h-12 border border-gray-300 rounded-lg px-4 focus:outline-none focus:border-[#247C8F]"
                />
              </div>
              {terror && <p class="text-red-500">{terror}</p>}
              <button
                class="bg-[#247C8F] hover:bg-[#005B6E] text-white font-bold py-2 px-7 rounded-lg self-start mt-3 disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={handleTrackSubmit}
                disabled={isTrackingNumberEmpty}
              >
                Track
              </button>
            </div>
          </div>
        </div>
        {/* <div class="w-full md:w-1/2 border-b md:border-r border-gray-300">
            <div class="p-8">
              <h2 class="text-xl font-semibold text-gray-800 mb-4">
                Find Location
              </h2>
              <div class="flex flex-col">
                <input
                  type="text"
                  placeholder="Search Location "
                  // value={searchLocation}
                  // onChange={(e) => setSearchLocation(e.target.value)}
                  class="w-full h-12 mb-4 border border-gray-300 rounded-lg px-4 focus:outline-none focus:border-[#247C8F]"
                />
                {terror && <p class="text-red-500">{terror}</p>}
                <button
                  class="bg-[#247C8F] hover:bg-[#005B6E] text-white font-bold py-2 px-7 rounded-lg self-start mt-3 disabled:opacity-50 disabled:cursor-not-allowed"
                  // onClick={handleLocationSearch}
                  // disabled={isLocationEmpty}
                >
                  Search
                </button>
              </div>
            </div>
          </div> */}
        <div class="w-full md:w-1/2">
          <div class="p-8">
            <h2 class="text-xl font-semibold text-gray-800 mb-4">
              REQUEST A QUOTE
            </h2>
            <p class="text-gray-600 mb-6 text-sxl">
              Get in contact with our expertise.
            </p>
            <button
              class="bg-[#247C8F] hover:bg-[#005B6E] text-white font-bold py-2 px-7 rounded-lg"
              onClick={handleQuotationModalOpen}
            >
              Get a Quotation
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {/* <div className=" flex justify-center w-auto">
        <img className=" w-full rounded-md" src="./assets/Hero.png" alt="Logo" />
      </div> */}
      {/* <div className="flex justify-center w-auto">
        <img
          className="w-full"
          src="./assets/Hero.png"
          alt="Placeholder Image"
        />
      </div> */}

      <div style={{ minheight: "120vh", height: "auto", marginBottom: "15px" }}>
        <BackgroundVideo
          blur={2}
          videoSource={videoSource}
          placeholderImage={placeholder}
          card={card}
        >
          <div className="pt-32 lg:ml-20">
            <div className="mt-10 px-2  md:px-12 ml-2 md:ml-10 w-full flex flex-col md:flex-row max-w-5xl">
              {/* <img
                className="w-1/2 md:w-1/4 h-auto"
                src="../assets/logo2.png"
                alt="Logo"
              /> */}

              <img
                className="w-1/2 md:w-1/4 h-auto"
                src="../assets/Logo2.png"
                alt="Logo"
              />
              <div className="py-6 md:py-12 md:pl-6 flex flex-col justify-center">
                <h2 className="pt-6 md:pt-0 px-6 md:px-10 text-2xl md:text-3xl lg:text-6xl tracking-normal leading-snug font-extrabold text-[#247C8F] max-w-lg">
                  INPUT AFRICA
                </h2>
                <hr className="w-full border-t-2 border-[#247C8F] mx-6 md:mx-10 my-4" />
                <h1 className="px-6 md:px-10 text-xl md:text-2xl lg:text-xl tracking-normal leading-snug font-extrabold text-[#247C8F] max-w-lg">
                  Your Trusted Global Forwarding Partner <br /> Making Logistics
                  Effortless!
                </h1>
              </div>
            </div>
            {/* Place your card content here */}
          </div>
        </BackgroundVideo>
      </div>

      <Modal open={isQuotationModalOpen} onClose={handleQuotationCloseModal}>
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 md:p-10 w-full max-w-lg rounded-lg shadow-lg flex flex-col justify-between">
            <div className="flex justify-end">
              <button
                onClick={handleQuotationCloseModal}
                className="text-gray-500 hover:text-gray-800 focus:outline-none"
              >
                <AiOutlineClose className="h-6 w-6" />
              </button>
            </div>
            <div className="flex justify-center mb-4">
              <h1 className="text-xl md:text-2xl font-semibold mt-2">
                Get a Quotation
              </h1>
            </div>
            <form
              className="flex flex-col flex-grow"
              onSubmit={handleQuotationSubmit} // Call your submit function
            >
              {/* Full Name */}
              <div className="mb-4">
                <input
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  className={`w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-[#247C8F] ${
                    formSubmitted && formData.fullName.trim() === ""
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {/* Display error message if fullName is empty and form is submitted */}
                {formSubmitted && formData.fullName.trim() === "" && (
                  <p className="text-red-500">Please enter your full name.</p>
                )}
              </div>

              {/* Email */}

              <div className="mb-4 flex gap-4">
                <div className="w-full md:w-1/2">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className={`w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-[#247C8F] ${
                      formSubmitted && formData.email.trim() === ""
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  {/* Display error message if email is empty and form is submitted */}
                  {formSubmitted && formData.email.trim() === "" && (
                    <p className="text-red-500">Please enter your email.</p>
                  )}
                </div>

                <div className="w-full md:w-1/2">
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      height: "var(--react-international-phone-height, 42px)",
                      padding: "1.25rem 0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "0.4rem",
                      outline: "none",
                      focus: {
                        borderColor: "#247C8F",
                      },
                      ...(formSubmitted &&
                        formData.phone.trim() === "" && {
                          borderColor: "#ff0000",
                        }),
                    }}
                    countrySelectorStyleProps={{
                      style: {
                        marginRight: "5px",
                      },
                      buttonStyle: {
                        width: "100%",
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                        borderRadius: "0.4rem",
                        padding: "1.25rem 0.5rem",
                        marginRight: "5px",
                      },
                    }}
                    placeholder="Phone"
                    defaultCountry="et"
                    value={formData.phone}
                    onChange={handlePhoneChange}
                  />

                  {formSubmitted && formData.phone.trim() === "" && (
                    <p className="text-red-500">
                      Please enter your phone number.
                    </p>
                  )}
                </div>
              </div>

              {/* Origin Country */}
              <div className="mb-4 flex gap-4">
                <div className="w-full md:w-1/2">
                  <select
                    name="originCountry"
                    value={formData.originCountry}
                    onChange={handleInputChange}
                    className={`w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-[#247C8F] ${
                      formSubmitted && formData.originCountry.trim() === ""
                        ? "border-red-500"
                        : ""
                    }`}
                  >
                    <option value="">Origin Country</option>
                    <option value="usa">United States</option>
                    <option value="canada">Canada</option>
                    <option value="uk">United Kingdom</option>
                  </select>
                  {/* Display error message if originCountry is empty and form is submitted */}
                  {formSubmitted && formData.originCountry.trim() === "" && (
                    <p className="text-red-500">
                      Please select your origin country.
                    </p>
                  )}
                </div>

                {/* Destination Country */}
                <div className="mb-4">
                  <select
                    name="destinationCountry"
                    value={formData.destinationCountry}
                    onChange={handleInputChange}
                    className={`w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-[#247C8F] ${
                      formSubmitted && formData.destinationCountry.trim() === ""
                        ? "border-red-500"
                        : ""
                    }`}
                  >
                    <option value="">Destination Country</option>
                    <option value="usa">United States</option>
                    <option value="canada">Canada</option>
                    <option value="uk">United Kingdom</option>
                  </select>
                  {/* Display error message if destinationCountry is empty and form is submitted */}
                  {formSubmitted &&
                    formData.destinationCountry.trim() === "" && (
                      <p className="text-red-500">
                        Please select your destination country.
                      </p>
                    )}
                </div>
              </div>
              {/* Comment */}
              <textarea
                name="comment"
                value={formData.comment}
                onChange={handleInputChange}
                placeholder="Comment"
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-[#247C8F] resize-none"
                rows={4}
              />

              {/* Submit button */}
              <div className="mt-5 flex justify-center items-center">
                <button
                  className="bg-[#247C8F] hover:bg-[#005B6E] text-white font-bold py-2 px-4 rounded-lg w-full md:w-auto"
                  disabled={rloading}
                  type="submit"
                >
                  {loading ? "Requesting..." : "Request Quotation"}
                </button>
              </div>

              {/* Display general error message */}
              {formSubmitted && rerror && (
                <div className="mt-3 text-red-500 text-center">{rerror}</div>
              )}
            </form>
          </div>
        </div>
      </Modal>
      <Modal open={successModalOpen} onClose={handleCloseSuccessModal}>
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 md:p-10 w-full max-w-lg rounded-lg shadow-lg flex flex-col justify-between">
            <div className="flex justify-end">
              <button
                onClick={handleCloseSuccessModal}
                className="text-gray-500 hover:text-gray-800 focus:outline-none"
              >
                <AiOutlineClose className="h-6 w-6" />
              </button>
            </div>
            <div className="flex justify-center mb-4">
              <h1 className="text-xl md:text-2xl font-semibold mt-2">
                Thank you!
              </h1>
            </div>
            <div className="flex justify-center mb-4">
              <p className="text-gray-700">
                Your request has been sent to our expert team. Once the quote is
                ready, we will send it to your email.
              </p>
            </div>
            <div className="mt-5 flex justify-center items-center">
              <button
                className="bg-[#247C8F] hover:bg-[#005B6E] text-white font-bold py-2 px-4 rounded-lg"
                onClick={handleCloseSuccessModal}
              >
                Got it!
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={isTrackingModalOpen} onClose={handleCloseModal}>
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-10 w-1/2 max-w-screen max-h-screen rounded-lg shadow-lg flex flex-col justify-between">
            <div className="flex justify-end">
              <button
                onClick={handleCloseModal}
                className="text-gray-500 hover:text-gray-800 focus:outline-none"
              >
                <AiOutlineClose className="h-6 w-6" />{" "}
                {/* Close button with close icon */}
              </button>
            </div>
            <div className="flex justify-center mb-4">
              <h1 className="text-2xl font-semibold mt-2">Shipment Details</h1>
            </div>
            {loading ? (
              <div>Loading...</div>
            ) : error ? (
              <div>Error: {error}</div>
            ) : !shipmenttrackInfo && !shipmentcrackInfo ? (
              <div className="flex justify-center items-center">
                <div className="text-center">
                  No shipment information available
                </div>
              </div>
            ) : trackingType === "BOL" ? (
              <div className="overflow-auto flex-grow">
                {/* <p>
                  <strong>Company Name:</strong>{" "}
                  {shipmenttrackInfo.company.companyName}
                </p> */}
                <p>
                  <strong>Declaration Number:</strong>{" "}
                  {shipmenttrackInfo.declarationNumber}
                </p>
                <p>
                  <strong>Number of Containers:</strong>{" "}
                  {shipmenttrackInfo.containers.length}
                </p>
                <p>
                  <strong>Created On:</strong>{" "}
                  {formattedDate(shipmenttrackInfo.createdAt)}
                </p>
                <p>
                  <strong>Origin:</strong> {shipmenttrackInfo.route.originName}
                </p>
                <p>
                  <strong>Destination:</strong>{" "}
                  {shipmenttrackInfo.route.destinationName}
                </p>
                <p>
                  <strong>Total Distance:</strong>{" "}
                  {shipmenttrackInfo.route.totalDistance}
                </p>
                <p>
                  <strong>Estimated Delivery Date:</strong>{" "}
                  {formattedDate(shipmenttrackInfo.estimatedArrivalDate)}
                </p>
                <p>
                  <strong>Status:</strong> {shipmenttrackInfo.status}
                </p>
              </div>
            ) : trackingType === "Container" ? (
              <div className="overflow-auto flex-grow">
                {/* Render specific container preview information here */}
                <p>
                  <strong>Transportation Type:</strong>{" "}
                  {shipmentcrackInfo.transportationType}
                </p>
                <p>
                  <strong>Status:</strong> {shipmentcrackInfo.status}
                </p>
                {/* <p>
                  <strong>Volume:</strong> {shipmentcrackInfo.volume}
                </p>
                <p>
                  <strong>Contents:</strong> {shipmentcrackInfo.contents}
                </p> */}
                {/* Add more container-specific details as needed */}
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Hero;
//  <div className="flex flex-col xl:flex-row justify-center items-center ">
//    <div className="  py-10 mr-8 ">
//      <h1 className=" text-3xl md:text-4xl lg:text-5xl tracking-normal leading-snug font-extrabold text-[#247C8F] max-w-2xl     ">
//        Trusted Global Forwarding "EASY TO DO BUSINESS WITH"
//      </h1>
//    </div>

//    <div class="max-w-5xl w-full md:w-3/4 p-4 rounded-lg mx-auto">
//      <div class="bg-white rounded-lg shadow-xl overflow-hidden flex flex-col md:flex-row">
//        <div
//          class="w-full md:w-1/2 border-b md:border-r border-gray-300"
//          id="track"
//        >
//          <div class="p-8">
//            <h2 class="text-xl font-semibold text-gray-800 mb-4">
//              Track Shipment
//            </h2>
//            <div class="flex flex-col">
//              <input
//                type="text"
//                placeholder="Enter Tracking Number"
//                value={trackingNumber}
//                onChange={(e) => setTrackingNumber(e.target.value)}
//                class="w-full h-12 mb-4 border border-gray-300 rounded-lg px-4 focus:outline-none focus:border-[#247C8F]"
//              />
//              {terror && <p class="text-red-500">{terror}</p>}
//              <button
//                class="bg-[#247C8F] hover:bg-[#005B6E] text-white font-bold py-2 px-7 rounded-lg self-start mt-3 disabled:opacity-50 disabled:cursor-not-allowed"
//                onClick={handleTrackSubmit}
//                disabled={isTrackingNumberEmpty}
//              >
//                Track
//              </button>
//            </div>
//          </div>
//        </div>
//        <div class="w-full md:w-1/2">
//          <div class="p-6">
//            <h2 class="text-xl font-semibold text-gray-800 mb-8">
//              REQUEST A QUOTE
//            </h2>
//            <p class="text-gray-600 mb-6 text-sxl">
//              Get in contact with our expertise.
//            </p>
//            <button
//              class="bg-[#247C8F] hover:bg-[#005B6E] text-white font-bold py-2 px-4 rounded-lg"
//              onClick={handleQuotationModalOpen}
//            >
//              Get a Quotation
//            </button>
//          </div>
//        </div>
//      </div>
//    </div>

{
  /* <div className=" max-w-3xl">
            <p className=" text-xl mb-12 ">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Doloremque itaque quasi nesciunt ipsam maxime, pariatur fuga qui
              provident corporis dolorum blanditiis nam, error rerum eaque
              libero ipsa minus delectus omnis!
            </p>
          </div> */
}
//  </div>;
