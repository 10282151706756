import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/auth.slice";
import { authApi } from "./service/auth.service";

const store = configureStore({
	reducer: {
		auth: authReducer,
		[authApi.reducerPath]: authApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(authApi.middleware),
});

export default store;
