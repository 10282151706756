export const fadeIn = (direction, delay) => {
  return {
    hidden: {
      y: direction === "up" ? 60 : direction === "down" ? -60 : 0,
      x: direction === "left" ? 100 : direction === "right" ? -100 : 0,
    },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        type: "tween",
        duration: 1.2,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    },
  };
};

export const fadeInUp = {
  hidden: {
    y: 60,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 1.2,
      ease: [0.25, 0.25, 0.25, 0.75],
    },
  },
};

export const fadeInDown = {
  hidden: {
    y: -60,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 1.2,
      ease: [0.25, 0.25, 0.25, 0.75],
    },
  },
};

export const fadeInLeft = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 1.2,
      ease: [0.25, 0.25, 0.25, 0.75],
    },
  },
};

export const fadeInRight = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 1.2,
      ease: [0.25, 0.25, 0.25, 0.75],
    },
  },
};

export const fadeInCenter = {
  hidden: {
    scale: 0.5,
    opacity: 0,
  },
  show: {
    scale: 1,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 1.2,
      ease: [0.25, 0.25, 0.25, 0.75],
    },
  },
};

export const fadeInUpBig = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 1.2,
      ease: [0.25, 0.25, 0.25, 0.75],
    },
  },
};

export const fadeInDownBig = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 1.2,
      ease: [0.25, 0.25, 0.25, 0.75],
    },
  },
};

export const fadeInLeftBig = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 1.2,
      ease: [0.25, 0.25, 0.25, 0.75],
    },
  },
};

export const fadeInRightBig = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 1.2,
      ease: [0.25, 0.25, 0.25, 0.75],
    },
  },
};

export const appear = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      type: "tween",
      duration: 2,
      ease: [0.25, 0.25, 0.25, 0.75],
    },
  },
};
