//  import React,{useState} from "react";

// // const BackgroundVideo = ({ videoSource, children, blur }) => {
// //   return (
// //     <div
// //       className="container"
// //       style={{ overflow: "hidden", width: "100%" }}
// //     >
// //       <video
// //         style={{
// //           filter: `blur(${blur}px)`,
// //           WebkitFilter: `blur(${blur}px)`,
// //           width: "100%",
// //           position: "absolute",
// //           top: "0",
// //           left: "0",
// //           zIndex: "-1",
// //         }}
// //         autoPlay
// //         loop
// //         muted
// //         id="video-id"
// //         className="video"
// //       >
// //         {/* TODO make it accept multiple media types */}
// //         <source src={videoSource} type="video/mp4" />
// //         Your browser does not support the video tag.
// //       </video>
// //       <div style={{  zIndex: "1" }}>{children}</div>
// //     </div>
// //   );
// // };

// // export default BackgroundVideo;
// const BackgroundVideo = ({ videoSource, placeholderImage, children, blur }) => {
//   const [videoLoaded, setVideoLoaded] = useState(false);

//   const handleVideoLoad = () => {
//     setVideoLoaded(true);
//   };

//   return (
//     <div className="container" style={{ overflow: "hidden", width: "100%" }}>
//       {!videoLoaded && (
//         <img
//           src={placeholderImage}
//           alt="Placeholder"
//           style={{
//             width: "100%",
//             position: "absolute",
//             top: "0",
//             left: "0",
//             zIndex: "-1",
//           }}
//         />
//       )}
//       <video
//         style={{
//           filter: `blur(${blur}px)`,
//           WebkitFilter: `blur(${blur}px)`,
//           width: "100%",
//           position: "absolute",
//           top: "0",
//           left: "0",
//           zIndex: videoLoaded ? "-1" : "0",
//         }}
//         autoPlay
//         loop
//         muted
//         id="video-id"
//         className="video"
//         onLoadedData={handleVideoLoad}
//       >
//         {/* TODO make it accept multiple media types */}
//         <source src={videoSource} type="video/mp4" />
//         Your browser does not support the video tag.
//       </video>
//       <div style={{ zIndex: "1" }}>{children}</div>
//     </div>
//   );
// };
// export default BackgroundVideo;
import React, { useState, useRef, useEffect } from "react";

const BackgroundVideo = ({ card,videoSource, placeholderImage, children, blur }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef(null);

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  useEffect(() => {
    const resizeHandler = () => {
      if (videoRef.current) {
        const video = videoRef.current;
        const container = video.parentElement;
        const videoRatio = video.videoWidth / video.videoHeight;
        const containerRatio = container.offsetWidth / container.offsetHeight;

        if (videoRatio > containerRatio) {
          video.style.width = "auto";
          video.style.height = "100%";
        } else {
          video.style.width = "100%";
          video.style.height = "auto";
        }
      }
    };

    window.addEventListener("resize", resizeHandler);
    resizeHandler(); // Call resizeHandler initially

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <div
      className="relative container mx-auto "
      style={{ overflow: "hidden", maxWidth: "100%" }}
    >
      {!videoLoaded && (
        <img
          src={placeholderImage}
          alt="Placeholder"
          className="absolute inset-0 object-cover w-full h-full"
        />
      )}
      <video
        ref={videoRef}
        style={{
          filter: `blur(${blur}px)`,
          WebkitFilter: `blur(${blur}px)`,
          width: "100%",
          height: "auto",
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: videoLoaded ? "-1" : "0",
        }}
        autoPlay
        loop
        muted
        id="video-id"
        className="object-cover w-full h-full"
        onLoadedData={handleVideoLoad}
      >
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="relative z-10 sm:-mt-10">{children}</div>
      <div className=" relative z-10 pt-12 pb-2 lg:pl-10 ">{card}</div>
    </div>
  );
};

export default BackgroundVideo;
