import React from "react";
import Our_Story from "../Components/About-Us/Our_Story";
import Navbar from "../Components/Layout/Navbar";
import Works from "../Components/About-Us/Works";
const AboutUs = () => {
  return (
    <div>
     
      <Our_Story />
      <Works />

    </div>
  );
};

export default AboutUs;
