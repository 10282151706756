import React from "react";
import Banner from "../Reusable/Banner";
import Container from "../Reusable/Container";
import OurServices from "./OurServices";

const DoortoDoorDelivery = () => {
  return (
    <div>
     
      <h1 className=" text-center text-5xl pt-20 pb-12 px-4 font-bold text-[#247C8F] ">
        Our Services
      </h1>
      <Container>
        <div className="  max-w-[1240px] mx-auto grid md:grid-cols-2 gap-5 pb-24 md:py-24 my-12 md:my-0">
          <div className="flex flex-col">
            <h1 className="text-xl mt-2 mb-2 px-4 font-bold text-[#247b8fb9] max-w-2xl">
              Door to Door Delivery
            </h1>

            <p className="text-lg mt-8 text-[#919B9D] max-w-2xl text-start mx-4">
              Our door to door delivery services offer seamless transportation solutions
              from pick-up to final destination. Whether you're shipping locally or
              internationally, we ensure reliable and efficient delivery of your goods
              right to the recipient's doorstep.
            </p>
            
            <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
              With a focus on customer satisfaction, we handle every step of the delivery
              process with care and precision. From scheduling and logistics planning to
              real-time tracking and updates, we provide peace of mind knowing your
              shipment arrives on time and in perfect condition.
            </p>

            <div className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
              <li>Efficient and reliable door to door shipping services</li>
              <li>Personalized logistics solutions tailored to your needs</li>
              <li>Secure handling and packaging for all types of shipments</li>
              <li>Trackable shipments with updates at every stage</li>
              {/* <li>Professional customer support available 24/7</li> */}
              <li>Competitive pricing with transparent cost structures</li>
              <li>Flexible delivery options to meet your specific requirements</li>
            </div>

            <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
              Whether you're sending documents, parcels, or freight, our door to door
              delivery services ensure hassle-free shipping solutions. Contact us today
              to discuss your delivery needs and experience our commitment to excellence
              in logistics.
            </p>
          </div>

          <div className=" flex justify-center w-auto p-8 md:p-0">
            <img className=" w-full rounded-md" src="../assets/DoortoDoor.jfif" alt="Door to Door Image" />
          </div>
        </div>
      </Container>

      <OurServices />
    </div>
  );
};

export default DoortoDoorDelivery;
