import React, { useState } from "react";

import { IoMdClose } from "react-icons/io";
import { MdMenu } from "react-icons/md";
// import Logo from "../../assets/Logo.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogout } from "../../store/actions/auth.action";

export default function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = React.useState(false);

  // Assuming the shipment info is stored in the Redux store
  const toggleMenu = () => {
    setState(!state);
  };
  const handleLogout = () => {
    dispatch(userLogout()) // Dispatch the userLogout action thunk
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Logout failed:", error);
      });
  };

  const menus = [
    { title: "Home", path: "/" },
    { title: "About Us", path: "/about-us" },
    { title: "Services", path: "/services" },
    { title: "Contact Us", path: "/contact" },
  ];
  const isLoggedIn = !!localStorage.getItem("userToken");

  return (
    <>
      <nav className="bg-white bg-opacity-90 shadow-md w-full sticky top-0 z-50 transition-colors duration-300">
        <div className="items-center px-4 max-w-screen-xl mx-auto md:flex md:px-8 md:justify-between">
          <div className="flex items-center justify-between py-2 md:py-2 md:block">
            <a
              href="/"
              className="flex justify-center items-center gap-2 text-3xl"
            >
              <img
                src="../assets/Logo2.png"
                alt="Logo"
                height={80}
                width={80}
              />
            </a>
            <div className="md:hidden">
              <button
                className="text-gray-700 outline-none p-2 rounded-md focus:border-gray-400 focus:border"
                onClick={toggleMenu}
              >
                {state ? <IoMdClose size={40} /> : <MdMenu size={40} />}
              </button>
            </div>
          </div>
          <div
            className={`flex-1 pb-3 mt-8 md:pb-0 md:mt-0 md:flex md:items-center md:justify-center ${
              state ? "block" : "hidden"
            }`}
          >
            <ul className="space-y-4 text-lg md:flex md:space-x-6 md:space-y-0 md:text-base">
              {menus.map((item, idx) => (
                <li key={idx} className="text-black">
                  <a
                    href={item.path}
                    className="block px-3 py-2 transform transition duration-300 ease-in-out hover:text-customHover hover:scale-105"
                  >
                    {item.title}
                  </a>
                </li>
              ))}
              {isLoggedIn && (
                <li className="text-black">
                  <a href="/user" className="block px-3 py-2">
                    My Shipments
                  </a>
                </li>
              )}
            </ul>
            <div className="flex flex-col md:hidden mt-4 space-y-2">
              {isLoggedIn ? (
                <Button
                  style={{
                    backgroundColor: "#247C8F",
                    "&:hover": {
                      backgroundColor: "#005B6E", // New background color on hover
                    },
                    color: "white",
                    padding: "6px 12px", // Smaller padding for small screens
                    fontSize: "0.875rem", // Smaller font size for small screens
                  }}
                  variant="contained"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#247C8F",
                    "&:hover": {
                      backgroundColor: "#005B6E", // New background color on hover
                    },
                    color: "white",
                    padding: "6px 12px", // Smaller padding for small screens
                    fontSize: "0.875rem", // Smaller font size for small screens
                  }}
                  variant="contained"
                  onClick={() => navigate("/login")}
                >
                  Login
                </Button>
              )}
            </div>
          </div>
          <div className="hidden md:flex md:gap-5">
            {isLoggedIn ? (
              <Button
                style={{
                  backgroundColor: "#247C8F",
                  "&:hover": {
                    backgroundColor: "#005B6E", // New background color on hover
                  },
                  color: "white",
                }}
                variant="contained"
                onClick={handleLogout}
              >
                Logout
              </Button>
            ) : (
              <Button
                // style={{
                //   backgroundColor: "#247C8F",
                //   "&:hover": {
                //     backgroundColor: "#005B6E", // New background color on hover
                //   },
                //   color: "white",
                // }}
                variant="text"
  sx={{
    color: "rgb(118, 113, 113)",
    ':hover': {
      bgcolor: '#005B6E', 
      color: 'white',
    },
  }}
                style={{
                  transition: "all 0.5s ease 0s",
                //  color: "rgb(118, 113, 113)",
                  border: "1.5px solid rgb(198, 187, 187)",
                  textTransform: "uppercase",
                  textAlign: "center",
                  lineHeight: 1,
                  fontSize: "15px",
                 // backgroundColor: "transparent",
                  padding: "10px",
                  outline: "none",
                  borderRadius: "7px"
                }}

                //variant="contained"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
              
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
//  <>
//    <div className="flex justify-end">
//      <button
//        onClick={handleCloseModal}
//        className="text-gray-500 hover:text-gray-800 focus:outline-none"
//      >
//        <AiOutlineClose className="h-6 w-6" />{" "}
//        {/* Close button with close icon */}
//      </button>
//    </div>
//    <div className="bg-white p-4 max-w-md mx-auto my-32 rounded-md shadow-lg">
//      <Typography variant="h6" className="mb-4">
//        Shipment Details
//      </Typography>
//      {shipmenttrackInfo ? (
//        <div>
//          <p>Shipment ID: {shipmenttrackInfo._id}</p>
//          <p>Shipment Name: {shipmenttrackInfo.shipmentName}</p>
//          <p>Container Number: {shipmenttrackInfo.containerNumber}</p>
//          {/* Add more shipment details as needed */}
//        </div>
//      ) : loading ? (
//        <div>Loading...</div>
//      ) : error ? (
//        <div>Error: {error}</div>
//      ) : (
//        <div>No shipment information available</div>
//      )}
//    </div>
//  </>;
