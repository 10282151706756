import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[#242424] pt-4 px-4 md:px-0 tracking-wordSpacing text-white">
      {/* <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-8">
        
        <div className="md:col-span-1 flex justify-center">
          <div>
            <img
              className="  size-28 pt-5 "
              src="./assets/Logo2.png"
              alt="Logo"
            />
          </div>
       
        </div>

        
        <div className="md:col-span-2 flex flex-col">
          <div className="text-gray-500">
            <h3 className="text-xl font-bold mb-8 "></h3>

            <p>
              Panafric Global established in 1993 G.C, Serving a growing base of
              customers around the world, leading provider of multimodal
              transportation and logistics services.
            </p>
          </div>
        </div>

       
        <div className="md:col-span-2 flex flex-col">
          <h3 className="text-xl font-bold mb-2 ">We Are Members Of</h3>

          <div className="text-gray-500">
            <ul>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Lorem ipsum dolor sit amet</li>
            </ul>
          </div>
        </div>
      </div> */}

      {/* Copyright Section */}
      <div className="text-left mt-20 py-6  ml-12">
        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-12">
          {/* Column 1 */}
          <div className="md:col-span-1 flex flex-col mr-7">
            <div className="mr-6">
              <img
                className="  size-28  mb-5"
                src="../assets/Logo2.png"
                
                alt="Logo"
              />
              <p className="text-gray-500">
                Input Africa established in 1993 G.C, Serving a growing base of
                customers around the world, leading provider of multimodal
                transportation and logistics services.
              </p>
              <div className="mt-7">
                <div className="flex space-x-4">
                  <a
                    href="https://facebook.com"
                    target="_blank"
                    className="hover:text-blue-500"
                    rel="noreferrer"
                  >
                    <FaFacebook size={24} />
                  </a>
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    className="hover:text-blue-300"
                    rel="noreferrer"
                  >
                    <FaXTwitter size={24} />
                  </a>
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    className="hover:text-pink-500"
                    rel="noreferrer"
                  >
                    <FaInstagram size={24} />
                  </a>
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    className="hover:text-blue-700"
                    rel="noreferrer"
                  >
                    <FaLinkedin size={24} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="md:col-span-1 flex flex-col ">
            <div className="">
              <h3 className="text-xl font-bold mb-6">Our Services</h3>

              <ul class="list-disc list-inside">
                <li class="text-md py-2 text-[#919B9D] flex items-center">
                  <div
                    class="mr-4 h-4 w-4 bg-cover bg-center"
                    style={{ backgroundImage: `url(./assets/check1.png)` }}
                  ></div>

                  <a
                    href="/services"
                    className="text-[#919B9D] hover:text-[#247C8F]"
                  >
                    Air Freight
                  </a>
                </li>
                <li class="text-md py-2 text-[#919B9D] flex items-center">
                  <div
                    class="mr-4 h-4 w-4 bg-cover bg-center"
                    style={{ backgroundImage: `url(./assets/check1.png)` }}
                  ></div>

                  <a
                    href="/services"
                    className="text-[#919B9D] hover:text-[#247C8F]"
                  >
                    Ocean Freight
                  </a>
                </li>
                <li class="text-md py-2 text-[#919B9D] flex items-center">
                  <div
                    class="mr-4 h-4 w-4 bg-cover bg-center"
                    style={{ backgroundImage: `url(./assets/check1.png)` }}
                  ></div>

                  <a
                    href="/services"
                    className="text-[#919B9D] hover:text-[#247C8F]"
                  >
                    Customs Clearance
                  </a>
                </li>
                <li class="text-md py-2 text-[#919B9D] flex items-center">
                  <div
                    class="mr-4 h-4 w-4 bg-cover bg-center"
                    style={{ backgroundImage: `url(./assets/check1.png)` }}
                  ></div>
                  <a
                    href="/services"
                    className="text-[#919B9D] hover:text-[#247C8F]"
                  >
                    Logistics Consultancy
                  </a>
                </li>
                <li class="text-md py-2 text-[#919B9D] flex items-center">
                  <div
                    class="mr-4 h-4 w-4 bg-cover bg-center"
                    style={{ backgroundImage: `url(./assets/check1.png)` }}
                  ></div>

                  <a
                    href="/services"
                    className="text-[#919B9D] hover:text-[#247C8F]"
                  >
                    Port Handling
                  </a>
                </li>
                <li class="text-md py-2 text-[#919B9D] flex items-center">
                  <div
                    class="mr-4 h-4 w-4 bg-cover bg-center"
                    style={{ backgroundImage: `url(./assets/check1.png)` }}
                  ></div>
                  <a
                    href="/services"
                    className="text-[#919B9D] hover:text-[#247C8F]"
                  >
                    Special Cargo Handling
                  </a>
                </li>
                <li class="text-md py-2 text-[#919B9D] flex items-center">
                  <div
                    class="mr-4 h-4 w-4 bg-cover bg-center"
                    style={{ backgroundImage: `url(./assets/check1.png)` }}
                  ></div>

                  <a
                    href="/services"
                    className="text-[#919B9D] hover:text-[#247C8F]"
                  >
                    Door to Door Delivery
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Column 2 */}
          <div className="md:col-span-1 flex flex-col">
            <div className="">
              <h3 className="text-xl font-bold mb-6">Quick Links</h3>

              <ul class="list-disc list-inside">
                <li class="text-md py-2 text-[#919B9D] flex items-center">
                  <div
                    class="mr-4 h-4 w-4 bg-cover bg-center"
                    style={{ backgroundImage: `url(./assets/check1.png)` }}
                  ></div>
                  <a href="/" className="text-[#919B9D] hover:text-[#247C8F]">
                    Home
                  </a>
                </li>
                <li class="text-md py-2 text-[#919B9D] flex items-center">
                  <div
                    class="mr-4 h-4 w-4 bg-cover bg-center"
                    style={{ backgroundImage: `url(./assets/check1.png)` }}
                  ></div>
                  <a
                    href="/about-us"
                    className="text-[#919B9D] hover:text-[#247C8F]"
                  >
                    About Us
                  </a>
                </li>
                <li class="text-md py-2 text-[#919B9D] flex items-center">
                  <div
                    class="mr-4 h-4 w-4 bg-cover bg-center"
                    style={{ backgroundImage: `url(./assets/check1.png)` }}
                  ></div>
                  <a
                    href="/services"
                    className="text-[#919B9D] hover:text-[#247C8F]"
                  >
                    Services
                  </a>
                </li>
                <li class="text-md py-2 text-[#919B9D] flex items-center">
                  <div
                    class="mr-4 h-4 w-4 bg-cover bg-center"
                    style={{ backgroundImage: `url(./assets/check1.png)` }}
                  ></div>
                  <a
                    href="/contact"
                    className="text-[#919B9D] hover:text-[#247C8F]"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Column 3 */}
          <div className="md:col-span-1 flex flex-col">
            <h3 className="text-xl font-bold ml-12 mb-5">Contacts</h3>

            <div className="text-gray-500">
              <ul class="list-disc list-inside">
                <li class="text-sm py-2 text-[#919B9D] flex items-center mb-4">
                  <div
                    class="mr-4 -mt-7 h-10 w-10 bg-cover bg-center"
                    style={{ backgroundImage: `url(./assets/phone.png)` }}
                  ></div>
                  <div class="flex flex-col">
                    <h1 class="text-white font-bold text-lg">Phone Number</h1>
                    <p class="text-[#247C8F] text-md">+251964040404</p>
                    <p class="text-[#247C8F] text-md">+251964030303</p>
                    <p class="text-[#247C8F] text-md">+251114672664</p>
                  </div>
                </li>
                <li class="text-sm py-2 text-[#919B9D] flex items-center mb-5">
                  <div
                    class="mr-4 h-10 w-10 bg-cover bg-center -mt-5"
                    style={{ backgroundImage: `url(./assets/mail.png)` }}
                  ></div>
                  <div class="flex flex-col mb-3">
                    <h1 class="text-white font-bold text-lg">Email Adress</h1>
                    <p class="text-[#247C8F] text-md">
                      contact@inputafrica.com
                    </p>
                  </div>
                </li>
                <li class="text-sm py-2 text-[#919B9D] flex items-center">
                  <div
                    class="mr-4 h-10 w-10 bg-cover bg-center"
                    style={{ backgroundImage: `url(./assets/location.png)` }}
                  ></div>
                  <div class="flex flex-col">
                    <h1 class="text-white font-bold text-lg">Address</h1>
                    <p class="text-[#247C8F] text-md">2nd Floor Bitweded Bahiru Abreham tower, Addis Ababa, Ethiopia</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mt-16 py-6 border-t border-gray-700 mx-8">
        <div className="mx-auto max-w-7xl">
          <div className="flex flex-col sm:flex-row gap-8 justify-between items-center">
            <div>
              <p>© {currentYear} - All rights reserved.</p>
            </div>

            <div>
              <p>
                Designed & Developed by{" "}
                <a href="https://hillstech.de" className="text-blue-500">
                  HillsTech
                </a>
              </p>
            </div>

            <div>
              <div className="flex space-x-4">
                <a
                  href="https://facebook.com"
                  target="_blank"
                  className="hover:text-blue-500"
                  rel="noreferrer"
                >
                  <FaFacebook size={24} />
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  className="hover:text-blue-300"
                  rel="noreferrer"
                >
                  <FaXTwitter size={24} />
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  className="hover:text-pink-500"
                  rel="noreferrer"
                >
                  <FaInstagram size={24} />
                </a>
                <a
                  href="https://linkedin.com"
                  target="_blank"
                  className="hover:text-blue-700"
                  rel="noreferrer"
                >
                  <FaLinkedin size={24} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
{
  /* <div className="mt-2 flex justify-center ">
              <div className="flex space-x-4">
                <a
                  href="https://facebook.com"
                  target="_blank"
                  className="hover:text-blue-500"
                  rel="noreferrer"
                >
                  <FaFacebook size={24} />
                </a>

                <a
                  href="https://linkedin.com"
                  target="_blank"
                  className="hover:text-blue-700"
                  rel="noreferrer"
                >
                  <FaLinkedin size={24} />
                </a>
              </div>
            </div> */
}
