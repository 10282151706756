// import React from "react";
// import Banner from "../Components/Reusable/Banner";
// import Container from "../Components/Reusable/Container";
// import OurServices from "../Components/Services/OurServices";

// import { motion } from "framer-motion";
// import { fadeIn } from "../Components/Reusable/Variants";

// const Services = () => {
//   return (
//     <div>
//       {/* <Banner title="Our Services" /> */}
//       <h1 className=" text-center text-5xl pt-20 pb-12 px-4 font-bold text-[#247C8F] ">
//         Our Services
//       </h1>
//       <Container>
//         <div className=" max-w-[1240px] mx-auto grid md:grid-cols-2 gap-11 pb-24 md:py-24 my-12 md:my-0">
//           <motion.div
//             variants={fadeIn("right", 0.5)}
//             initial="hidden"
//             whileInView={"show"}
//             className=" flex flex-col justify-center "
//           > 
//           {/* Removed the followings. */}
//             {/* <h1 className=" text-3xl px-4 font-bold text-[#247C8F] max-w-2xl  -mt-12">
//               Integrated Service Solutions
//             </h1> */}
//             {/* This part to be removed and Integrated Service Solution part to be edited  */}
//             {/* <h1 className=" text-xl mt-2 mb-2 px-4 font-bold text-[#247b8fb9] max-w-2xl  ">
//               Tailored Solutions for Superior Supply Chain Management.
              
//             </h1> */}

//             <p className="text-lg mt-8  text-[#919B9D] max-w-2xl  text-start mx-4 ">
//             We specialize in seamless import and export 
//             services tailored to businesses of all sizes. Whether you're importing goods into Ethiopia 
//             or exporting products to international markets, we ensure efficient logistics with frequent 
//             departures on major trade routes and comprehensive inland services. 
//             </p>
//             <p className="text-lg mt-4  text-[#919B9D] max-w-2xl  text-start mx-4 ">
             
//             </p>
//           </motion.div>
//           <motion.div
//             variants={fadeIn("left", 0.5)}
//             initial="hidden"
//             whileInView={"show"}
//             className=" flex justify-center w-auto p-8 md:p-0"
//           >
//             <img className=" w-full rounded-md" src="./assets/About.png" alt="service" />
//           </motion.div>
//         </div>
//       </Container>

//       <OurServices />
//     </div>
//   );
// };

// export default Services;
import React from "react";
import Banner from "../Components/Reusable/Banner";
import Container from "../Components/Reusable/Container";
import OurServices from "../Components/Services/OurServices";

import { motion } from "framer-motion";
import { fadeIn } from "../Components/Reusable/Variants";

const Services = () => {
  return (
    <div>
      {/* <Banner title="Our Services" /> */}
      <h1 className="text-center text-5xl pt-20 pb-8 px-4 font-bold text-[#247C8F]">
        Our Services
      </h1>
      <Container>
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-11 pb-24 md:py-24 my-12 md:my-0">
          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className="flex flex-col mt-12 flex-1"
          >
            <p className="text-lg  text-[#919B9D] text-start mx-4">
              We specialize in seamless import and export services tailored to
              businesses of all sizes. Whether you're importing goods into
              Ethiopia or exporting products to international markets, we ensure
              efficient logistics with frequent departures on major trade routes
              and comprehensive inland services.
            </p>
            <p className="text-lg mt-4 text-[#919B9D] text-start mx-4">
              {/* Additional paragraph content can go here */}
              We specialize in seamless import and export services tailored to
              businesses of all sizes. Whether you're importing goods into
              Ethiopia or exporting products to international markets, we ensure
              efficient logistics with frequent departures on major trade routes
              and comprehensive inland services.
              
            </p>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className="flex justify-center flex-1 p-8 md:p-0"
          >
            <img
              className="rounded-md w-full h-3/4 object-cover "
              src="./assets/Services.jfif"
              alt="service"
            />
          </motion.div>
        </div>
      </Container>

      <OurServices />
    </div>
  );
};

export default Services;
