import React from 'react'
import Main from '../Components/User/Main'

const User = () => {
  return (
    <Main/>

  )
}

export default User