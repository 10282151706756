import React from "react";
import Banner from "../Reusable/Banner";
import Container from "../Reusable/Container";
import OurServices from "./OurServices";

const PortHandling = () => {
  return (
    <div>
     
      <h1 className=" text-center text-5xl pt-20 pb-12 px-4 font-bold text-[#247C8F] ">
        Our Services
      </h1>
      <Container>
        <div className="  max-w-[1240px] mx-auto grid md:grid-cols-2 gap-5 pb-24 md:py-24 my-12 md:my-0">
          <div className="flex flex-col">
              <h1 className="text-xl mt-2 mb-2 px-4 font-bold text-[#247b8fb9] max-w-2xl">
                Port Handling
              </h1>

              <p className="text-lg mt-8 text-[#919B9D] max-w-2xl text-start mx-4">
                Our port handling services ensure efficient and seamless operations for
                vessels and cargo at ports worldwide. With a focus on reliability and
                safety, we manage the complexities of port logistics to facilitate smooth
                and timely movements of goods.
              </p>
              
              <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                From container terminals to bulk cargo facilities, our experienced team
                handles all aspects of port handling with expertise. We prioritize
                compliance with regulations, optimal resource allocation, and proactive
                communication to enhance operational efficiency.
              </p>

              <div className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                <li>Comprehensive management of port operations</li>
                <li>Efficient loading and unloading of cargo</li>
                <li>Customized solutions for diverse vessel requirements</li>
                <li>Advanced logistics planning and execution</li>
                <li>Real-time monitoring and coordination</li>
                <li>Emergency response preparedness</li>
                <li>Continuous improvement initiatives</li>
              </div>

              <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                Whether you're handling imports, exports, or transshipment, our port
                handling services ensure operational excellence and customer satisfaction.
                Contact us today to discuss how we can optimize your port logistics and
                support your maritime operations.
              </p>
            </div>

          <div className=" flex justify-center w-auto p-8 md:p-0">
            <img className=" w-full rounded-md" src="../assets/PortHandling.png" alt="Port Handling image" />
          </div>
        </div>
      </Container>

      <OurServices />
    </div>
  );
};

export default PortHandling;
