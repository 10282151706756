import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchShipment } from "../../store/actions/auth.action";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { AiOutlineClose } from "react-icons/ai";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal, IconButton, TextField, Select, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { TbListDetails } from "react-icons/tb";
const Main = () => {
  const dispatch = useDispatch();
  const shipments = useSelector((state) => state.auth.shipmentInfo?.bols);
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const errorStatus = useSelector((state) => state.auth.errorStatus);
  const totalShipments = useSelector(
    (state) => state.auth.shipmentInfo?.totalData
  );
  const [totalMilestones, setTotalMilestones] = useState(0);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedShipment, setSelectedShipment] = useState(null); // Store details of selected shipment for modal
  const [isModalOpen, setIsModalOpen] = useState(false); // Track current page number
  const pageSize = 4; // Define page size (number of shipments per page)
  //  const totalMilestones = useSelector(
  //    (state) => state.auth.shipmentInfo?.milestones?.length || 0
  //  );
  const navigate = useNavigate();
useEffect(() => {
  const userToken = localStorage.getItem("userToken");
  if (!userToken || error === "Unautorized access") {
    navigate("/login");
  }
}, [navigate]);

useEffect(() => {
  dispatch(fetchShipment({ page: currentPage, limit: pageSize }));
}, [dispatch, currentPage, pageSize]);

  const totalPages = Math.ceil(totalShipments / pageSize);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  if (loading) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-200 z-50">
        <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-[#247C8F]"></div>
      </div>
    );
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  // const handleViewMore = (shipment) => {
  //   setSelectedShipment(shipment);
  //   // setTotalMilestones(shipment.milestones.length);
  //   setIsModalOpen(true);
  // };
  const handleViewMore = (billOfLadingId) => {
    // const selectedBillOfLading = shipments.find(
    //   (bol) => bol._id === billOfLadingId
    // );
    navigate(`/user/${billOfLadingId}`, {});
  };
  const formattedDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };
  return (
    <div className="bg-gray-100">
      <h2 className="text-5xl font-bold p-9 text-center bg-gray-100">
        Shipment Information
      </h2>
      <div className="flex flex-col md:flex-row mt-10 min-h-screen font-sans">
        <div className="md:w-1/3 border-r border-gray-200 p-5 bg-gray-100">
          <div className="flex justify-between items-center mb-5">
            <h1 className="text-xl font-bold text-center bg-gray-100">
              Bill Of Laddings
            </h1>
          </div>

          <div className="max-h-[800px] overflow-y-auto">
            {shipments && shipments.length > 0 ? (
              shipments.map((shipment) => (
                <div
                  key={shipment._id}
                  className="p-4 border border-gray-300 rounded-lg mb-2 bg-white cursor-pointer hover:bg-gray-200"
                  onClick={() => setSelectedShipment(shipment)}
                >
                  <h4 className="font-bold mb-4">
                    Bol#: {shipment.declarationNumber}
                  </h4>
                  <p className="mb-2">
                    {shipment.route.originName} to{" "}
                    {shipment.route.destinationName}
                  </p>
                  <p className="mb-4">
                    Created On: {formattedDate(shipment.createdAt)}
                  </p>
                  <span
                    className={
                      shipment.status === "Delivery"
                        ? "px-2 py-1 bg-green-500 text-white rounded-lg text-sm"
                        : "px-2 py-1 bg-gray-500 text-white rounded-lg text-sm"
                    }
                  >
                    {shipment.status}
                  </span>
                </div>
              ))
            ) : (
              <div className="p-4 text-center text-gray-500">
                No shipments available
              </div>
            )}
          </div>
        </div>

        <div className="flex-1 p-5 bg-gray-50">
          {selectedShipment && (
            <>
              <div className="flex flex-col md:flex-row justify-between items-center mb-5">
                <h2>Bol#: {selectedShipment.declarationNumber}</h2>
                <div className="flex gap-2"></div>
              </div>

              <div className="flex flex-col lg:flex-row gap-10 mb-5">
                <div className="flex-1 mb-5 lg:mb-0">
                  <h3 className="text-lg font-semibold mb-3">Overview</h3>
                  <div className="bg-white p-5 rounded-lg shadow-sm h-3/4">
                    <ul className="list-none p-0">
                      <li className="flex items-center my-2">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-green-500 mr-3"
                        />
                        Bill of Ladding Number:{" "}
                        {selectedShipment.billOfLadingNumber}.
                      </li>
                      <li className="flex items-center my-2">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-green-500 mr-3"
                        />
                        Company Name: {selectedShipment.company.companyName}.
                      </li>
                      <li className="flex items-center my-2">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-green-500 mr-3"
                        />
                        Total Containers: {selectedShipment.containers.length}.
                      </li>
                      <li className="flex items-center my-2">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-green-500 mr-3"
                        />
                        Departure Date:{" "}
                        {formattedDate(selectedShipment.departureDate)}.
                      </li>
                      <li className="flex items-center my-2">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-green-500 mr-3"
                        />
                        Estimated Arrival Date:{" "}
                        {formattedDate(selectedShipment.estimatedArrivalDate)}.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="flex-1">
                  <h3 className="text-lg font-semibold mb-3">Containers</h3>
                  <div className="max-h-[400px] overflow-y-auto bg-white p-5 rounded-lg shadow-sm">
                    {selectedShipment.containers &&
                    selectedShipment.containers.length > 0 ? (
                      <ul className="list-none p-0">
                        {selectedShipment.containers.map((container, index) => (
                          <li
                            key={index}
                            className="flex items-start mb-2 relative pl-5 cursor-pointer"
                            onClick={() => setSelectedContainer(container)}
                          >
                            <div className="absolute left-0 top-1.5 w-2 h-2 bg-indigo-600 rounded-full"></div>
                            <div className="ml-2">
                              <strong>{container.containerNumber}</strong>
                              <p>
                                <span
                                  className={
                                    container.status === "completed"
                                      ? "px-2 py-1 bg-green-500 text-white rounded-lg text-sm"
                                      : "px-2 py-1 bg-gray-500 text-white rounded-lg text-sm"
                                  }
                                >
                                  {container.status}
                                </span>
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No containers yet</p>
                    )}
                  </div>
                </div> */}
                <div className="flex-1">
                  <h3 className="text-lg font-semibold mb-3">Containers</h3>
                  <div className="max-h-[400px] overflow-y-auto bg-white p-5 rounded-lg shadow-sm">
                    {selectedShipment.containers &&
                    selectedShipment.containers.length > 0 ? (
                      <ul className="list-none p-0">
                        {selectedShipment.containers.map((container, index) => (
                          <li
                            key={index}
                            className="flex items-start mb-2 relative pb-3 pl-5 cursor-pointer transition duration-300 ease-in-out transform hover:bg-gray-100 hover:scale-105"
                            onClick={() => setSelectedContainer(container)}
                          >
                            <div className="absolute left-0 top-1.5 w-2 h-2 bg-indigo-600 rounded-full"></div>
                            <div className="ml-2">
                              <strong className="pb-4">
                                {container.containerNumber}
                              </strong>
                              <p>
                                <span
                                  className={
                                    container.status === "completed"
                                      ? "px-2 py-1 bg-green-500 text-white rounded-lg text-sm "
                                      : "px-2 py-1 bg-gray-500 text-white rounded-lg text-sm "
                                  }
                                >
                                  {container.status}
                                </span>
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No containers yet</p>
                    )}
                  </div>
                </div>
              </div>
              {/* <div>
               <h3 className="text-lg font-semibold mt-5 mb-3">Description</h3>
               <div className="bg-white p-5 rounded-lg shadow-sm">
                 <p>{selectedShipment.description}</p>
               </div>
             </div> */}
              {selectedContainer && (
                <div>
                  <h3 className="text-lg font-semibold mt-5 mb-3">
                    Container Details
                  </h3>
                  <div className="bg-white p-5 rounded-lg shadow-sm">
                    {/* <p>Additional details if available...</p> */}

                    <div className="overflow-auto flex-grow">
                      {/* <p>
                <strong>Shipment Name:</strong> {selectedShipment.shipmentName}
              </p> */}
                      <p>
                        <strong>Container Number:</strong>{" "}
                        {selectedContainer.containerNumber}
                      </p>
                      <p>
                        <strong>Created On:</strong>{" "}
                        {formattedDate(selectedContainer.createdAt)}
                      </p>
                      {/* <p>
                <strong>Origin:</strong> {selectedShipment.route.originName}
              </p>
              <p>
                <strong>Destination:</strong>{" "}
                {selectedShipment.route.destinationName}
              </p>
              <p>
                <strong>Total Distance:</strong>{" "}
                {selectedShipment.route.totalDistance}
              </p> */}
                      <p>
                        <strong>Milestones:</strong>
                      </p>
                      <div className="max-h-[400px] overflow-y-auto">
                        <Stepper
                          activeStep={totalMilestones}
                          orientation="vertical"
                        >
                          {selectedContainer.milestones.map(
                            (milestone, index) => (
                              <Step key={milestone._id}>
                                <StepLabel style={{ color: "#1976d2" }}>
                                  <strong
                                    style={{
                                      fontSize:
                                        index === totalMilestones
                                          ? "1.3em"
                                          : "1em",
                                    }}
                                  >
                                    {milestone.name}
                                  </strong>
                                  <Typography
                                    style={{
                                      color:
                                        index === totalMilestones
                                          ? "#1976d2"
                                          : "inherit",
                                    }}
                                  >
                                    {milestone.event}
                                  </Typography>
                                </StepLabel>
                                <StepContent style={{ color: "#1976d2" }}>
                                  <Typography>
                                    Days to complete:{" "}
                                    {milestone.expectedDaysToComplete} days
                                  </Typography>
                                </StepContent>
                              </Step>
                            )
                          )}
                        </Stepper>
                      </div>
                    </div>
                    <p className="mt-4">
                      <strong>Delivery Date:</strong>
                      {"   "}
                      {formattedDate(selectedContainer.arrivalDate)}
                    </p>
                    <p>
                      <strong>Transportation Type:</strong>{" "}
                      {selectedContainer.transportationType}
                    </p>
                    {selectedContainer.transportationType === "train" && (
                      <p>
                        <strong>Wagon Number:</strong>{" "}
                        {selectedContainer.wagonNumber}
                      </p>
                    )}
                    {selectedContainer.transportationType === "truck" && (
                      <div>
                        <p>
                          <strong>Driver Name:</strong>{" "}
                          {selectedContainer.driverDetails.name}
                        </p>
                        <p>
                          <strong>Plate Number:</strong>{" "}
                          {selectedContainer.driverDetails.phone}
                        </p>
                        <p>
                          <strong>Phone Number:</strong>{" "}
                          {selectedContainer.driverDetails.plateNumber}
                        </p>
                      </div>
                    )}
                    {/* Render only if selectedContainer.additionalEventMessage exists */}
                    {selectedContainer.additionalEventMessage && (
                      <p>
                        <strong>Additional Event Message:</strong>{" "}
                        {selectedContainer.additionalEventMessage}
                      </p>
                    )}

                    {/* Render only if selectedContainer.lastMilestoneChangeDate exists */}
                    {selectedContainer.lastMilestoneChangeDate && (
                      <p>
                        <strong>Last Milestone Change Date:</strong>{" "}
                        {formattedDate(
                          selectedContainer.lastMilestoneChangeDate
                        )}
                      </p>
                    )}
                    <p>
                      <strong>Status:</strong> {selectedContainer.status}
                    </p>

                    {/* <button
              onClick={() => setIsModalOpen(false)}
              className="px-4 py-2 bg-blue-500 text-white rounded-md mt-4"
            >
              Close
            </button> */}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
  // return (
  //   <div className="bg-gray-100">
  //     <h2 className="text-5xl font-bold p-9 text-center bg-gray-100">
  //       Shipment Information
  //     </h2>
  //     <div className="flex flex-col md:flex-row mt-10 min-h-screen font-sans">
  //       <div className="md:w-1/3 border-r border-gray-200 p-5 bg-gray-100">
  //         <div className="flex justify-between items-center mb-5">
  //           <h1 className="text-xl font-bold text-center bg-gray-100">
  //             Bill Of Laddings
  //           </h1>
  //         </div>

  //         <div className="max-h-[800px] overflow-y-auto">
  //           {shipments && shipments.length > 0 ? (
  //             shipments.map((shipment) => (
  //               <div
  //                 key={shipment._id}
  //                 className="p-4 border border-gray-300 rounded-lg mb-2 bg-white cursor-pointer hover:bg-gray-200"
  //                 onClick={() => setSelectedShipment(shipment)}
  //               >
  //                 <h4 className="font-bold mb-4">
  //                   Bol#: {shipment.declarationNumber}
  //                 </h4>
  //                 <p className="mb-2">
  //                   {shipment.route.originName} to{" "}
  //                   {shipment.route.destinationName}
  //                 </p>
  //                 <p className="mb-4">
  //                   Created On: {formattedDate(shipment.createdAt)}
  //                 </p>
  //                 <span
  //                   className={
  //                     shipment.status === "Delivery"
  //                       ? "px-2 py-1 bg-green-500 text-white rounded-lg text-sm"
  //                       : "px-2 py-1 bg-gray-500 text-white rounded-lg text-sm"
  //                   }
  //                 >
  //                   {shipment.status}
  //                 </span>
  //               </div>
  //             ))
  //           ) : (
  //             <div className="p-4 text-center text-gray-500">
  //               No shipments available
  //             </div>
  //           )}
  //         </div>
  //       </div>

  //       <div className="flex-1 p-5 bg-gray-50">
  //         {selectedShipment && (
  //           <>
  //             <div className="flex flex-col md:flex-row justify-between items-center mb-5">
  //               <h2>Bol#: {selectedShipment.declarationNumber}</h2>
  //               <div className="flex gap-2"></div>
  //             </div>

  //             <div className="flex flex-col lg:flex-row gap-10 mb-10">
  //               <div className="flex-1 mb-5 lg:mb-3">
  //                 <h3 className="text-lg font-semibold mb-3">Overview</h3>
  //                 <div className="bg-white p-5 rounded-lg shadow-sm h-3/4">
  //                   <ul className="list-none p-0">
  //                     <li className="flex items-center my-2">
  //                       <FontAwesomeIcon
  //                         icon={faCheckCircle}
  //                         className="text-green-500 mr-3"
  //                       />
  //                       Bill of Ladding Number:{" "}
  //                       {selectedShipment.billOfLadingNumber}.
  //                     </li>
  //                     <li className="flex items-center my-2">
  //                       <FontAwesomeIcon
  //                         icon={faCheckCircle}
  //                         className="text-green-500 mr-3"
  //                       />
  //                       Company Name: {selectedShipment.company.companyName}.
  //                     </li>
  //                     <li className="flex items-center my-2">
  //                       <FontAwesomeIcon
  //                         icon={faCheckCircle}
  //                         className="text-green-500 mr-3"
  //                       />
  //                       Total Containers: {selectedShipment.containers.length}.
  //                     </li>
  //                     <li className="flex items-center my-2">
  //                       <FontAwesomeIcon
  //                         icon={faCheckCircle}
  //                         className="text-green-500 mr-3"
  //                       />
  //                       Departure Date:{" "}
  //                       {formattedDate(selectedShipment.departureDate)}.
  //                     </li>
  //                     <li className="flex items-center my-2">
  //                       <FontAwesomeIcon
  //                         icon={faCheckCircle}
  //                         className="text-green-500 mr-3"
  //                       />
  //                       Estimated Arrival Date:{" "}
  //                       {formattedDate(selectedShipment.estimatedArrivalDate)}.
  //                     </li>
  //                   </ul>
  //                 </div>
  //               </div>
  //               <div className="flex-1">
  //                 <h3 className="text-lg font-semibold mb-3">Containers</h3>
  //                 <div className="max-h-[400px] overflow-y-auto bg-white p-5 rounded-lg shadow-sm">
  //                   {selectedShipment.containers &&
  //                   selectedShipment.containers.length > 0 ? (
  //                     <ul className="list-none p-0">
  //                       {selectedShipment.containers.map((container, index) => (
  //                         <li
  //                           key={index}
  //                           className="flex items-start mb-2 relative pl-5"
  //                         >
  //                           <div className="absolute left-0 top-1.5 w-2 h-2 bg-indigo-600 rounded-full"></div>
  //                           <div className="ml-2">
  //                             <strong>{container.containerNumber}</strong>
  //                             <p>
  //                               <span
  //                                 className={
  //                                   container.status === "completed"
  //                                     ? "px-2 py-1 bg-green-500 text-white rounded-lg text-sm"
  //                                     : "px-2 py-1 bg-gray-500 text-white rounded-lg text-sm"
  //                                 }
  //                               >
  //                                 {container.status}
  //                               </span>
  //                             </p>
  //                           </div>
  //                         </li>
  //                       ))}
  //                     </ul>
  //                   ) : (
  //                     <p>No containers yet</p>
  //                   )}
  //                 </div>
  //               </div>
  //             </div>
  //             <div>
  //               <h3 className="text-lg font-semibold  mt-5 mb-3">
  //                 Description
  //               </h3>
  //               <div className="bg-white p-5  rounded-lg shadow-sm">
  //                 <p>{selectedShipment.description}</p>
  //               </div>
  //             </div>
  //           </>
  //         )}
  //       </div>
  //     </div>
  //   </div>

  // );
};

export default Main;
