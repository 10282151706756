import React, { useState } from "react";
import Container from "../Reusable/Container";
import Banner from "../Reusable/Banner";
import { motion } from "framer-motion";
import { fadeIn } from "../Reusable/Variants";
const ContactUs = () => {
  const backgroundImage = {
    backgroundImage: `url('./contactimg.png')`,
    backgroundSize: "cover", // Add other background properties as needed
    // ... other background properties
  };
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { fullName, email, phoneNumber, subject, message } = formData;

    const recipient = "contact@inputafrica.com";
    const body = `Full Name: ${fullName}\nEmail: ${email}\nPhone Number: ${phoneNumber}\nMessage: ${message}`;
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    try {
      window.location.href = mailtoLink;
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Error sending message. Please try again later.");
    }
  };

  return (
    <>
      <h1 className=" text-center text-5xl pt-20 pb-6 px-4 font-bold text-[#247C8F] ">
        Contact Us
      </h1>
      {/* <Banner title="Contact Us" /> */}
      <Container>
        <section className="py-20 px-6 sm:px-12 relative z-10" id="contact">
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row max-1220:flex-col lg:items-start text-slate-900 mb-4 lg:justify-between -mx-5">
              {/* LEFT SIDE */}
              <div className="w-full max-1220:w-full lg:w-1/2 bg-white/5 bg-opacity-70 backdrop-filter mb-12 mr-12 p-6 lg:-mt-9 lg:p-12">
                <motion.div
                  variants={fadeIn("right", 0.5)}
                  initial="hidden"
                  whileInView="show"
                  className=""
                >
                  <div className="relative rounded-lg p-6 lg:p-8 shadow-2xl backdrop-blur-2xl backdrop-filter">
                    <div className="mb-5 flex flex-col max-w-md text-white">
                      <h1 className="text-3xl font-bold max-w-2xl mb-2">
                        Contact Us
                      </h1>
                    </div>
                    <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
                      <div className="mb-6">
                        <input
                          type="text"
                          placeholder="Full Name"
                          className="w-full rounded p-3 text-gray-800 border border-gray-500 outline-none focus-visible:shadow-none focus:border-primary"
                          name="fullName"
                          value={formData.fullName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <input
                          type="tel"
                          placeholder="Phone Number"
                          className="w-full rounded p-3 text-gray-800 border border-gray-500 outline-none focus-visible:shadow-none focus:border-primary"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleChange}
                        />
                        <input
                          type="email"
                          placeholder="Your Email"
                          className="w-full rounded p-3 text-gray-800 border border-gray-500 outline-none focus-visible:shadow-none focus:border-primary"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-6">
                        <input
                          inputMode="text"
                          placeholder="Subject"
                          className="w-full rounded p-3 text-gray-800 border border-gray-500 outline-none focus-visible:shadow-none focus:border-primary"
                          value={formData.subject}
                          onChange={handleChange}
                          name="subject"
                        />
                      </div>
                      <div className="mb-6">
                        <textarea
                          rows="6"
                          placeholder="Your Message"
                          className="w-full rounded p-3 text-gray-800 border border-gray-500 outline-none focus-visible:shadow-none focus:border-primary"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="w-full text-gray-100 hover:text-gray-300 bg-[#247C8F] rounded p-3 transition ease-in-out duration-500 hover:bg-[#005B6E]"
                        >
                          Send Message
                        </button>
                      </div>
                    </form>
                  </div>
                </motion.div>
              </div>

              {/* RIGHT SIDE */}
              <div className="w-full lg:w-1/2  md:w-full px-6 ml-4 lg:px-12">
                <motion.div
                  variants={fadeIn("left", 0.5)}
                  initial="hidden"
                  whileInView="show"
                  className="flex justify-center w-auto p-8 md:p-0"
                >
                  <ul className="list-none flex flex-col lg:flex-row justify-between items-start lg:space-x-8 space-y-5 lg:space-y-0">
                    <li className="text-sm py-2 pr-3 text-[#919B9D] flex items-start lg:items-center mb-4">
                      <div
                        className="mr-4 h-8 w-8 bg-cover bg-center lg:-mt-20"
                        style={{ backgroundImage: `url(./assets/phone.png)` }}
                      ></div>
                      <div className="flex flex-col p-2">
                        <h1 className="font-bold text-lg mb-1">Phone Number</h1>
                        <p className="text-[#247C8F] text-lg">+251964040404</p>
                        <p className="text-[#247C8F] text-lg">+251964030303</p>
                        <p className="text-[#247C8F] text-lg">+251114672664</p>
                      </div>
                    </li>
                    <li className="text-sm py-2 text-[#919B9D] flex flex-col items-start lg:items-center mb-4">
                      <div className="flex items-center">
                        <div
                          className="mr-4 h-8 w-8 -mt-7 bg-cover bg-center"
                          style={{ backgroundImage: `url(./assets/mail.png)` }}
                        ></div>
                        <div className="flex flex-col p-2">
                          <h1 className="font-bold text-lg mb-1">
                            Email Address
                          </h1>
                          <p className="text-[#247C8F] text-lg">
                            contact@inputafrica.com
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center mt-4 lg:mt-0">
                        <div
                          className="mr-4 h-8 w-8 -mt-7 bg-cover bg-center"
                          style={{ backgroundImage: `url(./assets/fax.png)` }}
                        ></div>
                        <div className="flex flex-col lg:mr-14 p-2">
                          <h1 className="font-bold text-lg mb-1">Fax</h1>
                          <p className="text-[#247C8F] text-lg">
                            +251114672664
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="text-sm py-2 text-[#919B9D] flex items-start lg:items-center mb-4">
                      <div
                        className="mr-4 h-8 w-8 -mt-6 bg-cover bg-center lg:-mt-10"
                        style={{
                          backgroundImage: `url(./assets/location.png)`,
                        }}
                      ></div>
                      <div className="flex flex-col p-2">
                        <h1 className="font-bold text-lg mb-1">Address</h1>
                        <p className="text-[#247C8F] text-lg">
                        2nd Floor Bitweded Bahiru Abreham Tower
                        </p>
                      </div>
                    </li>
                  </ul>
                </motion.div>
                <div className="mb-12 mt-4">
                  <motion.div
                    variants={fadeIn("left", 0.5)}
                    initial="hidden"
                    whileInView="show"
                    className="flex justify-center w-auto p-8 md:p-0"
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.627397511694!2d38.763922574066626!3d9.006390489344417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b851c9d7d6ac3%3A0x6b0962f7b2a7ba85!2sInput%20Africa%20Worldwide%20Logistics%20and%20Forwarding!5e0!3m2!1sam!2set!4v1722407571502!5m2!1sam!2set"
                      width="100%"
                      height="370"
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
};

export default ContactUs;
{
  /* <div>
                  <h1 className="text-3xl pt-2 pb-10 font-normal text-[#247C8F] max-w-2xl">
                    Address
                  </h1>
                </div> */
}
{
  /* 
              <table className="border-collapse w-full mt-4 text-xl">
                <tbody>
                  <tr>
                    <td className="pr-8 font-semibold pb-2">Phone number:</td>
                    <td>+251964040404</td>
                  </tr>
                  <tr>
                    <td className="pr-8 font-semibold pb-2"></td>
                    <td>+251964030303</td>
                  </tr>
                  <tr>
                    <td className="pr-8 font-semibold pb-2 mb-5"></td>
                    <td>+251114672664</td>
                  </tr>
                  <tr>
                    <td className="pr-8 font-semibold pb-2">Email Address:</td>
                    <td>contact@inputafrica.com </td>
                  </tr>
                  <tr>
                    <td className="pr-8 font-semibold pb-2">Fax:</td>
                    <td>+251114672663</td>
                  </tr>
                </tbody>
              </table> */
}
