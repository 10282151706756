import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import AddContainerButton from "../components/common/AddContainerButton";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Modal,
  Button,
  Box,
  Select,
  MenuItem,
  Switch,
  TextField,
  Badge,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import CardActions from "@mui/material/CardActions";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AiOutlineClose } from "react-icons/ai";
import { LuContainer } from "react-icons/lu";
 import { AiOutlineBell } from "react-icons/ai";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const DetailPage = () => {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [billOfLading, setBillOfLading] = useState(null);
  const [formValidity, setFormValidity] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [containers, setContainers] = useState([]);
 
  const token = sessionStorage.getItem("token");

  const baseURL = "https://shipment-management-gwbb.onrender.com/api/v1";
  const navigate = useNavigate();
  const fetchBillOfLading = async () => {
    try {
      const response = await axios.get(`${baseURL}/bol/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setBillOfLading(response.data.bol);
        setLoading(false);
      } else {
        console.error("Failed to fetch bill of lading");
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBillOfLading();
  }, [id]);

  // const handleEdit = (containerId) => {
  //   // Handle edit functionality
  //   console.log("Edit container:", containerId);
  // };
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedShipment, setEditedShipment] = useState({
    id: "",
    containerNumber: "",
    departureDate: new Date(),
    currentMilestone: "",
    arrivalDate: new Date(),
    transportationType: "",
    driverDetails: { name: "", phone: "", plateNumber: "" },
    wagonNumber: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  // Other functions...
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleEdit = (id) => {
    const selectedContainer = billOfLading.containers.find(
      (container) => container._id === id
    );

    const editedShipmentData = {
      id: selectedContainer._id,
      containerNumber: selectedContainer.containerNumber,
      departureDate: formatDate(selectedContainer.departureDate),
      arrivalDate: formatDate(selectedContainer.arrivalDate),
      transportationType: selectedContainer.transportationType,
      status: selectedContainer.status,
    };

    if (selectedContainer.transportationType === "truck") {
      editedShipmentData.driverDetails = {
        name: selectedContainer.driverDetails.name,
        phone: selectedContainer.driverDetails.phone,
        plateNumber: selectedContainer.driverDetails.plateNumber,
      };
    } else if (selectedContainer.transportationType === "train") {
      editedShipmentData.wagonNumber = selectedContainer.wagonNumber;
    }

    setEditedShipment(editedShipmentData);
    setEditModalOpen(true);
    setIsEditing(true);
  };

  // const handleEdit = (id) => {
  //   const selectedContainer = billOfLading.containers.find(
  //     (container) => container._id === id
  //   );
  //   setEditedShipment({
  //     containerNumber: selectedContainer.containerNumber,
  //     departureDate: formatDate(selectedContainer.departureDate),
  //     arrivalDate: formatDate(selectedContainer.arrivalDate),
  //     transportationType: selectedContainer.transportationType,
  //     driverDetails: {
  //       name: selectedContainer.driverDetails.name,
  //       phone: selectedContainer.driverDetails.phone,
  //       plateNumber: selectedContainer.driverDetails.plateNumber,
  //     },
  //     wagonNumber: selectedContainer.wagonNumber,
  //     status: selectedContainer.status,
  //   });
  //   //  setIsDelayed(selectedShipment.isDelayed); // Set the isDelayed state based on the selected shipment
  //   //  setdelayReason(selectedShipment.delayReason || ""); // Set the delayReason state based on the selected shipment
  //   setEditModalOpen(true);
  //   setIsEditing(true);
  // };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCardClick = (shipmentId) => {
    setSelectedContainer(shipmentId === selectedContainer ? null : shipmentId);
  };
  const handleDelete = (containerId) => {
    setSelectedContainer(containerId);

    setShowModal(true);
  };
  const confirmDelete = async () => {
    console.log(selectedContainer);
    try {
      const response = await axios.delete(
        `${baseURL}/bol/remove-container/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: { containerId: selectedContainer }, // Send data in the request body
        }
      );
      if (response.status === 200) {
        // Reload data after successful deletion
        fetchBillOfLading();
      } else {
        console.error("Failed to delete container");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setShowModal(false);
    }
  };

  const handleClick = (containerId) => {
    // Handle click functionality
    console.log("Clicked container:", containerId);
  };
  const handleNewShipmentCreated = async (shipmentId) => {
    try {
      const baseURL = process.env.REACT_APP_BASE_URL;
      // Replace with your actual token
      // Replace with your actual bill of lading ID

      // Fetch the container details using the shipmentId
      const response = await axios.get(
        `${baseURL}/shipment/by-container-number/${shipmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Extract the container details
      setContainers(response.data.shipment);

      // Add the container to the bill of lading
      const addContainerResponse = await axios.put(
        `${baseURL}/bol/add-containers/${id}`,
        {
          containers: [containers._id], // Assuming _id is the container ID
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (addContainerResponse.data.success) {
        fetchBillOfLading();
        // Success message or further actions
        console.log("Container added to the bill of lading successfully.");
      } else {
        console.error("Failed to add container to the bill of lading.");
      }
    } catch (error) {
      console.error("Error adding container to the bill of lading:", error);
    }
  };

  //   const handleViewMore = (
  //     shipmentContainerNumber,
  //     currentMilestone,
  //     previousMilestone
  //   ) => {
  //     navigate(`/shipments/${shipmentContainerNumber}`, {
  //       state: { currentMilestone, previousMilestone },
  //     });
  //   };
  const [totalMilestones, setTotalMilestones] = useState(0);
  const [selectedShipment, setSelectedShipment] = useState(null); // Store details of selected shipment for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleViewMore = (shipment) => {
    setSelectedShipment(shipment);
    setTotalMilestones(shipment.milestones.length);
    setIsModalOpen(true);
  };
  //   // Call the function with the containerId when a new shipment is created
  //   handleNewShipmentCreated(containerId);
  const formattedDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };
  if (loading) {
    return (
      <div
        style={{
          width: "120px",
          height: "20px",
          WebkitMask: "linear-gradient(90deg,#000 70%,#0000 0) 0/20%",
          background: `linear-gradient(#009ee0 0 0) 0/0% no-repeat #ddd`,
          animation: "l4 2s infinite steps(6)",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "#009ee0", // Set text color to match primary color
        }}
      ></div>
    );
  }
  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "departureDate" || name === "arrivalDate") {
      // Parse the ISO date string into a Date object
      const date = new Date(value);
      // Get the individual date components
      const year = date.getFullYear(); // Get the full year
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month starts from 0, so add 1 and pad with zero if necessary
      const day = String(date.getDate()).padStart(2, "0"); // Get the day and pad with zero if necessary
      // Format the date as YYYY/MM/DD
      const formattedDate = `${year}-${month}-${day}`;
      setEditedShipment((prev) => ({
        ...prev,
        [name]: formattedDate, // Set the formatted date
      }));
    } else {
      setEditedShipment((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    setFormValidity(validateEditForm());
  };
  const handleDriverInputChange = (e) => {
    const { name, value } = e.target;
    setEditedShipment((prev) => ({
      ...prev,
      driverDetails: {
        ...prev.driverDetails,
        [name]: value,
      },
    }));
  };
  const handleEditSubmit = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const originalShipment = billOfLading.containers.find(
        (container) => container._id === editedShipment.id
      );

      const updatedFields = {};

      if (editedShipment.departureDate !== originalShipment.departureDate) {
        updatedFields.departureDate = editedShipment.departureDate;
      }
      if (editedShipment.arrivalDate !== originalShipment.arrivalDate) {
        updatedFields.arrivalDate = editedShipment.arrivalDate;
      }
      if (editedShipment.containerNumber !== originalShipment.containerNumber) {
        updatedFields.containerNumber = editedShipment.containerNumber;
      }
      if (
        editedShipment.transportationType !==
        originalShipment.transportationType
      ) {
        updatedFields.transportationType = editedShipment.transportationType;
      }
      if (editedShipment.status !== originalShipment.status) {
        updatedFields.status = editedShipment.status;
      }
      if (editedShipment.transportationType === "truck") {
        // Compare and update driver details only if the transportation type is truck
        if (
          editedShipment.driverDetails.name !==
          originalShipment.driverDetails.name
        ) {
          updatedFields.driverDetails = {
            ...updatedFields.driverDetails,
            name: editedShipment.driverDetails.name,
          };
        }
        if (
          editedShipment.driverDetails.phone !==
          originalShipment.driverDetails.phone
        ) {
          updatedFields.driverDetails = {
            ...updatedFields.driverDetails,
            phone: editedShipment.driverDetails.phone,
          };
        }
        if (
          editedShipment.driverDetails.plateNumber !==
          originalShipment.driverDetails.plateNumber
        ) {
          updatedFields.driverDetails = {
            ...updatedFields.driverDetails,
            plateNumber: editedShipment.driverDetails.plateNumber,
          };
        }
      }

      // Check if the transportation type is train
      if (editedShipment.transportationType === "train") {
        // Compare and update wagon number only if the transportation type is train
        if (editedShipment.wagonNumber !== originalShipment.wagonNumber) {
          updatedFields.wagonNumber = editedShipment.wagonNumber;
        }
      }

      await axios.put(
        `${baseURL}/shipment/${editedShipment.id}`,
        updatedFields,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Refresh shipments after editing
      fetchBillOfLading();
      setEditModalOpen(false);
      setIsEditing(false);
    } catch (error) {
      console.error("Error editing shipment:", error);
    }
  };
  const validateEditForm = () => {
    const originalShipment = billOfLading.containers.find(
      (container) => container._id === editedShipment.id
    );
    console.log(originalShipment.containerNumber);
    // Check if any input fields have been modified
    const isAnyFieldModified =
      editedShipment.containerNumber !== originalShipment.containerNumber ||
      editedShipment.status !== originalShipment.status ||
      editedShipment.departureDate !== originalShipment.departureDate ||
      editedShipment.arrivalDate !== originalShipment.arrivalDate ||
      (editedShipment.transportationType === "truck" &&
        (editedShipment.driverDetails.name !==
          originalShipment.driverDetails.name ||
          editedShipment.driverDetails.phone !==
            originalShipment.driverDetails.phone ||
          editedShipment.driverDetails.plateNumber !==
            originalShipment.driverDetails.plateNumber)) ||
      (editedShipment.transportationType === "train" &&
        editedShipment.wagonNumber !== originalShipment.wagonNumber);

    // Check if all input fields are empty
    const areAllFieldsEmpty =
      editedShipment.departureDate === "" &&
      editedShipment.arrivalDate === "" &&
      editedShipment.containerNumber === "" &&
      editedShipment.status === "" &&
      ((editedShipment.transportationType === "truck" &&
        (editedShipment.driverDetails.name === "" ||
          editedShipment.driverDetails.phone === "" ||
          editedShipment.driverDetails.plateNumber === "")) ||
        (editedShipment.transportationType === "train" &&
          editedShipment.wagonNumber === ""));

    return isAnyFieldModified && !areAllFieldsEmpty;
  };

  return (
    <>
      {isModalOpen && selectedShipment && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-10 w-1/2  max-w-screen max-h-screen rounded-lg shadow-lg flex flex-col justify-between">
            <div className="flex justify-end">
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500 hover:text-gray-800 focus:outline-none"
              >
                <AiOutlineClose className="h-6 w-6" />{" "}
                {/* Close button with close icon */}
              </button>
            </div>
            <div className="flex justify-center mb-4">
              <h1 className="text-2xl font-semibold mt-2">Shipment Details</h1>
            </div>
            <div className="overflow-auto flex-grow">
              {/* <p>
                <strong>Shipment Name:</strong> {selectedShipment.shipmentName}
              </p> */}
              <p>
                <strong>Container Number:</strong>{" "}
                {selectedShipment.containerNumber}
              </p>
              <p>
                <strong>Created On:</strong>{" "}
                {formattedDate(selectedShipment.creationDate)}
              </p>
              {/* <p>
                <strong>Origin:</strong> {selectedShipment.route.originName}
              </p>
              <p>
                <strong>Destination:</strong>{" "}
                {selectedShipment.route.destinationName}
              </p>
              <p>
                <strong>Total Distance:</strong>{" "}
                {selectedShipment.route.totalDistance}
              </p> */}
              <p>
                <strong>Milestones:</strong>{" "}
              </p>
              <Stepper activeStep={totalMilestones - 1} orientation="vertical">
                {selectedShipment.milestones.map((milestone, index) => (
                  <Step key={milestone._id}>
                    <StepLabel>
                      <strong>{milestone.name}</strong>
                      <Typography>{milestone.event}</Typography>
                    </StepLabel>
                    <StepContent>
                      <Typography>
                        Days to complete: {milestone.expectedDaysToComplete}{" "}
                        days
                      </Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
            <p className="mt-4">
              <strong>Delivery Date:</strong>
              {"   "}
              {formattedDate(selectedShipment.deliveryDate)}
            </p>
            <p>
              <strong>Status:</strong> {selectedShipment.status}
            </p>

            {/* <button
              onClick={() => setIsModalOpen(false)}
              className="px-4 py-2 bg-blue-500 text-white rounded-md mt-4"
            >
              Close
            </button> */}
          </div>
        </div>
      )}

      <div
        style={{
          marginTop: "30px",
          marginLeft: "20px",
          display: "flex",
          flexWrap: "wrap",
          gap: "25px",
          padding: "4rem",
          justifyContent: "center",
        }}
      >
        {billOfLading.containers.map((container) => (
          <Card
            onClick={() => handleCardClick(container._id)}
            key={container._id}
            sx={{
              width: "45%",
              marginBottom: "20px",
              borderRadius: "12px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
            variant="outlined"
          >
            <Badge
              color="secondary"
              variant="dot"
              overlap="circular"
              badgeContent={<AiOutlineBell />}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{
                position: "absolute",
                top: "8px",
                right: "8px",
                fontSize: "24px", // Make badge bigger
              }}
            >
              {/* You can customize the badge content here */}
              <span>New</span>
            </Badge>

            <CardContent>
              <Typography variant="h5" gutterBottom>
                <LuContainer
                  style={{
                    marginTop: "6px",
                    marginRight: "15px",
                    scale: "1.25",
                  }}
                />
                {container.containerNumber}
              </Typography>

              {/* <Typography variant="body1">
                <strong>Origin:</strong> {shipment.route.originName}
              </Typography>
              <Typography variant="body1">
                <strong>Destination:</strong> {shipment.route.destinationName}
              </Typography> */}
              <Typography variant="body1">
                <strong>Current Milestone:</strong>{" "}
                {container.currentMilestone
                  ? container.currentMilestone.name
                  : "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong>Deparature Date:</strong>{" "}
                {container.departureDate
                  ? formattedDate(container.departureDate)
                  : "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong>Estiamted Arrival Date:</strong>{" "}
                {container.arrivalDate
                  ? formattedDate(container.arrivalDate)
                  : "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong>Transportation Type:</strong>{" "}
                {container.transportationType}
              </Typography>
              <Typography variant="body1">
                <strong>Status: </strong> {container.status}
              </Typography>
            </CardContent>
            {/* <CardActions></CardActions> */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "4px",
              }}
            >
              <Button
                onClick={() => handleViewMore(container)}
                sx={{
                  cursor: "pointer",
                  borderRadius: "0.3rem",
                  marginLeft: "0.3rem",
                  backgroundColor: "transparent",
                  color: "#009ee0",
                  transition: "background-color 0.3s ease",
                  border: "none",
                  padding: "10px",
                  outline: "none",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#009ee0",
                    fontSize: "15px",
                  },
                }}
              >
                View More
              </Button>
              {/* <div>
                <IconButton
                  onClick={() => handleEdit(container._id)}
                  aria-label="edit"
                  sx={{ color: "green" }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleDelete(container._id)}
                  aria-label="delete"
                  sx={{ color: "red" }}
                >
                  <DeleteIcon />
                </IconButton>
              </div> */}
            </Box>
          </Card>
        ))}
      </div>
    </>
  );
};

export default DetailPage;
