import React from "react";
import Container from "../Reusable/Container";
import { TbTargetArrow } from "react-icons/tb";
import { ImLocation } from "react-icons/im";
import { BsStack } from "react-icons/bs";

import { motion } from "framer-motion";
import {
  fadeIn,
  fadeInDownBig,
  fadeInRight,
  fadeInRightBig,
} from "../Reusable/Variants";

const Works = () => {
  return (
    <Container>
      <div className="flex-col py-2 md:py-24 ">
        <div className="flex justify-center">
          <h1 className=" text-3xl pt-20 pb-10 font-bold text-[#247C8F] max-w-2xl  ">
            How it Works
          </h1>
        </div>
        <div className=" flex justify-center">
          <p className="text-sm pb-20 text-[#919B9D] max-w-2xl text-center  mx-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem,
            ipsa exercitationem dicta temporibus ullam iure et fugit doloremque
            tempore nulla, iusto enim iste? Error magni quisquam assumenda
            consectetur consequuntur saepe!
          </p>
        </div>
        <div className="max-w-[1240px] mx-auto grid  pb-8 md:grid-cols-3 gap-10">
          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className=" flex flex-col items-center  "
          >
            <h1 className="text-3xl py-5 font-bold text-[#65B8F3] max-w-2xl">
              <TbTargetArrow />
            </h1>
            <h1 className="text-xl py-2 font-bold text-[#247C8F] max-w-2xl">
              Track & Trace
            </h1>
            <p className="text-sm py-2  text-[#919B9D] max-w-2xl text-center mx-4">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Expedita, veritatis debitis? Ex nemo praesentium assumenda?{" "}
            </p>
          </motion.div>

          <motion.div
            variants={fadeInDownBig}
            initial="hidden"
            whileInView={"show"}
            className=" flex flex-col items-center  "
          >
            <h1 className="text-3xl py-5 font-bold text-[#65B8F3] max-w-2xl">
              <ImLocation />
            </h1>
            <h1 className="text-xl py-2 font-bold text-[#247C8F] max-w-2xl">
              Locate
            </h1>
            <p className="text-sm py-2  text-[#919B9D] max-w-2xl text-center mx-4">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Expedita, veritatis debitis? Ex nemo praesentium assumenda?{" "}
            </p>
          </motion.div>

          <motion.div
            variants={fadeInRightBig}
            initial="hidden"
            whileInView={"show"}
            className=" flex flex-col items-center  "
          >
            <h1 className="text-3xl py-5 font-bold text-[#65B8F3] max-w-2xl">
              <BsStack />
            </h1>
            <h1 className="text-xl py-2 font-bold text-[#247C8F] max-w-2xl">
              Get Quote
            </h1>
            <p className="text-sm py-2  text-[#919B9D] max-w-2xl text-center mx-4">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Expedita, veritatis debitis? Ex nemo praesentium assumenda?{" "}
            </p>
          </motion.div>
        </div>
      </div>
    </Container>
  );
};

export default Works;
