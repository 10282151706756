import { Container } from "@mui/material";
import React from "react";
// import story1 from "./assets/story.png";
// import mission2 from "./assets/mission.png"
import Banner from "../Reusable/Banner";

import { motion } from "framer-motion";
import { fadeIn } from "../Reusable/Variants";

const Our_Story = () => {
  return (
    <>
      {/* <h1 className="text-center pt-12 pb-12 text-[#247C8F] font-bold text-5xl">
        About Us
      </h1> */}
      <h1 className=" text-center text-5xl pt-20 pb-12 px-4 font-bold text-[#247C8F] ">
        About Us
      </h1>
      {/* <Banner title="About Us" /> */}
      {/* <div class=" my-10 py-10  mx-14 ">
        <div class="bg-gray-200 bg-opacity-20 rounded-lg overflow-hidden grid md:grid-cols-2 gap-4 md:gap-48 shadow-lg">
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="flex justify-center items-center p-8 md:p-0 relative"
          >
            <div class="relative">
              <img
                class="w-auto h-auto max-h-96 p-8"
                src="./assets/About_us2.jpg"
                alt="Logo"
              />
              <div class="absolute top-4 right-4 w-10 h-10 border-t-2 border-r-2 border-blue-400"></div>
            </div>
          </motion.div>

          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="p-8 md:p-10"
          >
            <h1 class="text-3xl pb-4 font-bold text-[#65B8F3]">Our Story</h1>
            <p class="text-sm py-2 text-[#919B9D]">
              Founded in 2008, Input Africa is a premium global logistics
              network and player, dedicated to delivering timely and dependable
              solutions supported by cutting-edge technology. We collaborate
              with a first-class worldwide network of logistics providers and
              business partners while promoting Ethiopia as an African and
              global logistics hub.
              <br />
              <br />
              As a leader in handling national logistics demand, we are
              committed to being the investment choice of our owners and the
              employment choice of our staff, enhancing the profitability of all
              our operations. At Input Africa, we foster a culture of continuous
              learning, providing regular in-house and external training for our
              employees to develop new skills for a challenging environment.
              <br />
              <br />
              We pride ourselves on adopting the latest technology to provide
              updated logistic services, including IFFS, ERP, and direct access
              to the Ethiopian Customs portal system. Our fully automated
              processes ensure efficiency and accuracy in every aspect of our
              operations.
              <br />
              <br />
              Headquartered in Addis Ababa, Ethiopia, Input Africa is a complete
              service provider for international transportation logistics
              solutions. With offices both domestically and abroad, and a team
              of highly competent professionals, we ensure that your cargo
              receives the care it deserves until it reaches its destination.
            </p>
          </motion.div>
        </div>
      </div>
      <div class=" my-10 py-10  mx-14 ">
        <div class="bg-gray-200 bg-opacity-20 rounded-lg overflow-hidden grid md:grid-cols-2 gap-4 md:gap-48 shadow-lg">
          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="p-8 md:p-10"
          >
            <h1 class="text-3xl pb-4 font-bold text-[#65B8F3]">Our Mission</h1>
            <p class="text-sm py-2 text-[#919B9D]">
              Our mission is simple: to always be "Easy To Work With." We aim to
              become one of the world’s top 10 providers of industrial logistics
              solutions by being passionate about understanding your supply
              chain, your needs, and the challenges you face in logistics.
              <br />
              <br />
            </p>
          </motion.div>

          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="flex justify-center items-center p-8 md:p-0 relative"
          >
            <div class="relative">
              <img
                class="w-auto h-auto max-h-96 p-8"
                src="./assets/About_us2.jpg"
                alt="Logo"
              />
              <div class="absolute top-4 right-4 w-10 h-10 border-t-2 border-r-2 border-blue-400"></div>
            </div>
          </motion.div>
        </div>
      </div>
      <div class=" my-10 py-10  mx-14 ">
        <div class="bg-gray-200 bg-opacity-20 rounded-lg overflow-hidden grid md:grid-cols-2 gap-4 md:gap-48 shadow-lg">
          <div class="flex justify-center items-center p-8 md:p-0 relative">
            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              class="relative"
            >
              <img
                class="w-auto h-auto max-h-96 p-8"
                src="./assets/About_us3.jpg"
                alt="Logo"
              />
              <div class="absolute top-4 right-4 w-10 h-10 border-t-2 border-r-2 border-blue-400"></div>
            </motion.div>
          </div>

          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="p-8 md:p-10"
          >
            <h1 class="text-3xl pb-4 font-bold text-[#65B8F3]">Our Vision</h1>
            <p class="text-sm py-2 text-[#919B9D]">
              Our vision is to be recognized worldwide as a leader in the field
              of end-to-end supply chain solutions. We believe in a world of
              free trade and interconnectivity, shaping the complexity of
              globalization to fulfill logistics tasks efficiently.
              <br />
              <br />
            </p>
          </motion.div>
        </div>
      </div>
      
      <div class="my-10 py-10 mx-14">
        <div class="bg-gray-200 bg-opacity-20 rounded-lg overflow-hidden grid md:grid-cols-2 gap-4 md:gap-48 shadow-lg">
          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="p-8 md:p-10"
          >
            <h1 class="text-3xl pb-4 font-bold text-[#65B8F3] mb-6">
              Our Values
            </h1>
            <ul class="list-disc list-inside">
              <li class="text-md py-2 text-[#919B9D] flex items-center">
                <div
                  class="mr-4 h-4 w-4 bg-cover bg-center"
                  style={{ backgroundImage: `url(./assets/tick.png)` }}
                ></div>
                Always putting our customers first.
              </li>
              <li class="text-md py-2 text-[#919B9D] flex items-center">
                <div
                  class="mr-4 h-4 w-4 bg-cover bg-center"
                  style={{ backgroundImage: `url(./assets/tick.png)` }}
                ></div>
                Seeking continuous and sustainable improvements.
              </li>
              <li class="text-md py-2 text-[#919B9D] flex items-center">
                <div
                  class="mr-4 h-4 w-4 bg-cover bg-center"
                  style={{ backgroundImage: `url(./assets/tick.png)` }}
                ></div>
                Honesty & Integrity.
              </li>
              <li class="text-md py-2 text-[#919B9D] flex items-center">
                <div
                  class="mr-4 h-4 w-4 bg-cover bg-center"
                  style={{ backgroundImage: `url(./assets/tick.png)` }}
                ></div>
                A partnership approach to our business.
              </li>
              <li class="text-md py-2 text-[#919B9D] flex items-center">
                <div
                  class="mr-4 h-4 w-4 bg-cover bg-center"
                  style={{ backgroundImage: `url(./assets/tick.png)` }}
                ></div>
                Accountablity for our decisions.
              </li>
            </ul>
          </motion.div>

          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="flex justify-center items-center p-8 md:p-0 relative"
          >
            <div class="relative">
              <img
                class="w-auto h-auto max-h-96 p-8"
                src="./assets/Aboutus_4.jpg"
                alt="Logo"
              />
              <div class="absolute top-4 right-4 w-10 h-10 border-t-2 border-r-2 border-blue-400"></div>
            </div>
          </motion.div>
        </div>
      </div>
  */}
      <div class="my-10 py-10 mx-4 md:mx-14">
        <div class="bg-gray-200 bg-opacity-20 rounded-lg overflow-hidden grid md:grid-cols-2 gap-4 shadow-lg">
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="flex justify-center items-center p-8 md:p-0 relative"
          >
            <div class="relative">
              <img
                class="w-auto h-auto max-h-96 p-8"
                src="./assets/About_us2.jpg"
                alt="Logo"
              />
              <div class="absolute top-4 right-4 w-10 h-10 border-t-2 border-r-2 border-blue-400"></div>
            </div>
          </motion.div>

          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="p-8 md:p-10"
          >
            <h1 class="text-3xl pb-4 font-bold text-[#65B8F3]">Our Story</h1>
            <p class="text-base md:text-sm py-2 text-[#919B9D]">
              Founded in 2008, Input Africa is a premium global logistics
              network and player, dedicated to delivering timely and dependable
              solutions supported by cutting-edge technology. We collaborate
              with a first-class worldwide network of logistics providers and
              business partners while promoting Ethiopia as an African and
              global logistics hub.
              <br />
              <br />
              As a leader in handling national logistics demand, we are
              committed to being the investment choice of our owners and the
              employment choice of our staff, enhancing the profitability of all
              our operations. At Input Africa, we foster a culture of continuous
              learning, providing regular in-house and external training for our
              employees to develop new skills for a challenging environment.
              <br />
              <br />
              We pride ourselves on adopting the latest technology to provide
              updated logistic services, including IFFS, ERP, and direct access
              to the Ethiopian Customs portal system. Our fully automated
              processes ensure efficiency and accuracy in every aspect of our
              operations.
              <br />
              <br />
              Headquartered in Addis Ababa, Ethiopia, Input Africa is a complete
              service provider for international transportation logistics
              solutions. With offices both domestically and abroad, and a team
              of highly competent professionals, we ensure that your cargo
              receives the care it deserves until it reaches its destination.
            </p>
          </motion.div>
        </div>
      </div>

      <div class="my-10 py-10 mx-4 md:mx-14">
        <div class="bg-gray-200 bg-opacity-20 rounded-lg overflow-hidden grid md:grid-cols-2 gap-4 shadow-lg">
          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="p-8 md:p-10"
          >
            <h1 class="text-3xl pb-4 font-bold text-[#65B8F3]">Our Mission</h1>
            <p class="text-base md:text-sm py-2 text-[#919B9D]">
              Our mission is simple: to always be "Easy To Work With." We aim to
              become one of the world’s top 10 providers of industrial logistics
              solutions by being passionate about understanding your supply
              chain, your needs, and the challenges you face in logistics.
              <br />
              <br />
            </p>
          </motion.div>

          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="flex justify-center items-center p-8 md:p-0 relative"
          >
            <div class="relative">
              <img
                class="w-auto h-auto max-h-96 p-8"
                src="./assets/About_us2.jpg"
                alt="Logo"
              />
              <div class="absolute top-4 right-4 w-10 h-10 border-t-2 border-r-2 border-blue-400"></div>
            </div>
          </motion.div>
        </div>
      </div>

      <div class="my-10 py-10 mx-4 md:mx-14">
        <div class="bg-gray-200 bg-opacity-20 rounded-lg overflow-hidden grid md:grid-cols-2 gap-4 shadow-lg">
          <div class="flex justify-center items-center p-8 md:p-0 relative">
            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              class="relative"
            >
              <img
                class="w-auto h-auto max-h-96 p-8"
                src="./assets/About_us3.jpg"
                alt="Logo"
              />
              <div class="absolute top-4 right-4 w-10 h-10 border-t-2 border-r-2 border-blue-400"></div>
            </motion.div>
          </div>

          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="p-8 md:p-10"
          >
            <h1 class="text-3xl pb-4 font-bold text-[#65B8F3]">Our Vision</h1>
            <p class="text-base md:text-sm py-2 text-[#919B9D]">
              Our vision is to be recognized worldwide as a leader in the field
              of end-to-end supply chain solutions. We believe in a world of
              free trade and interconnectivity, shaping the complexity of
              globalization to fulfill logistics tasks efficiently.
              <br />
              <br />
            </p>
          </motion.div>
        </div>
      </div>

      <div class="my-10 py-10 mx-4 md:mx-14">
        <div class="bg-gray-200 bg-opacity-20 rounded-lg overflow-hidden grid md:grid-cols-2 gap-4 shadow-lg">
          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="p-8 md:p-10"
          >
            <h1 class="text-3xl pb-4 font-bold text-[#65B8F3] mb-6">
              Our Values
            </h1>
            <ul class="list-disc list-inside">
              <li class="text-base md:text-md py-2 text-[#919B9D] flex items-center">
                <div
                  class="mr-4 h-4 w-4 bg-cover bg-center"
                  style={{ backgroundImage: `url(./assets/tick.png)` }}
                ></div>
                <p class="text-base md:text-sm py-2 text-[#919B9D]">
                  {" "}
                  Always putting our customers first.
                </p>
              </li>
              <li class="text-base md:text-md py-2 text-[#919B9D] flex items-center">
                <div
                  class="mr-4 h-4 w-4 bg-cover bg-center"
                  style={{ backgroundImage: `url(./assets/tick.png)` }}
                ></div>
                <p class="text-base md:text-sm py-2 text-[#919B9D]">
                  {" "}
                  Seeking continuous and sustainable improvements.
                </p>
              </li>
              <li class="text-base md:text-md py-2 text-[#919B9D] flex items-center">
                <div
                  class="mr-4 h-4 w-4 bg-cover bg-center"
                  style={{ backgroundImage: `url(./assets/tick.png)` }}
                ></div>
                <p class="text-base md:text-sm py-2 text-[#919B9D]">
                  {" "}
                  Honesty & Integrity.
                </p>
              </li>
              <li class="text-base md:text-md py-2 text-[#919B9D] flex items-center">
                <div
                  class="mr-4 h-4 w-4 bg-cover bg-center"
                  style={{ backgroundImage: `url(./assets/tick.png)` }}
                ></div>
                <p class="text-base md:text-sm py-2 text-[#919B9D]">
                  {" "}
                  A partnership approach to our business.
                </p>
              </li>
              <li class="text-base md:text-md py-2 text-[#919B9D] flex items-center">
                <div
                  class="mr-4 h-4 w-4 bg-cover bg-center"
                  style={{ backgroundImage: `url(./assets/tick.png)` }}
                ></div>
                <p class="text-base md:text-sm py-2 text-[#919B9D]">
                  {" "}
                  Accountability for our decisions.
                </p>
              </li>
            </ul>
          </motion.div>

          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="flex justify-center items-center p-8 md:p-0 relative"
          >
            <div class="relative">
              <img
                class="w-auto h-auto max-h-96 p-8"
                src="./assets/Aboutus_4.jpg"
                alt="Logo"
              />
              <div class="absolute top-4 right-4 w-10 h-10 border-t-2 border-r-2 border-blue-400"></div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Our_Story;
