import React from "react";
import Banner from "../Reusable/Banner";
import Container from "../Reusable/Container";
import OurServices from "./OurServices";

const CustomClearance = () => {
  return (
    <div>
     
      <h1 className=" text-center text-5xl pt-20 pb-12 px-4 font-bold text-[#247C8F] ">
        Our Services
      </h1>
      <Container>
        <div className="  max-w-[1240px] mx-auto grid md:grid-cols-2 gap-5 pb-24 md:py-24 my-12 md:my-0">
          <div className="flex flex-col">
              <h1 className="text-xl mt-2 mb-2 px-4 font-bold text-[#247b8fb9] max-w-2xl">
                Customs Clearance 
              </h1>

              <p className="text-lg mt-8 text-[#919B9D] max-w-2xl text-start mx-4">
                Our customs clearance services streamline the import and export processes,
                ensuring compliance with all regulations and timely clearance of your goods
                across borders. We handle the complexities of customs procedures, allowing
                you to focus on your core business activities.
              </p>
              
              <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                With a team of experienced customs brokers, we navigate the intricacies of
                tariffs, duties, and documentation requirements to expedite the clearance
                process. Whether you're shipping goods by air, sea, or land, we ensure
                seamless customs clearance for smooth logistics operations.
              </p>

              <div className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                <li>Expertise in handling import and export customs procedures</li>
                <li>Customized solutions tailored to your specific cargo requirements</li>
                <li>Efficient processing to minimize delays and maximize compliance</li>
                <li>Continuous monitoring and updates on regulatory changes</li>
                <li>Consultative approach to resolve customs-related challenges</li>
                <li>Transparent pricing and documentation management</li>
                {/* <li>24/7 support for all customs clearance inquiries</li> */}
              </div>

              <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                Whether you're a small business or a multinational corporation, our customs
                clearance services ensure hassle-free movement of your goods worldwide.
                Contact us today to discuss how we can optimize your customs clearance
                processes and enhance your supply chain efficiency.
              </p>
            </div>

          <div className=" flex justify-center w-auto p-8 md:p-0">
            <img className=" w-full rounded-md" src="../assets/CustomsClearance.jfif" alt="Customs Clearance image" />
          </div>
        </div>
      </Container>

      <OurServices />
    </div>
  );
};

export default CustomClearance;
