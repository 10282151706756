import React from "react";
import Marquee from "react-fast-marquee";
// import Kobil from "../../assets/kobil.png";
// import Castle from "../../assets/castle.png";

const Slider = () => {
  return (
    <>
      <h1 class="text-center text-3xl  font-bold text-[#247C8F] mb-18 sm:mb-6 md:mb-12 px-2 sm:px-4">
        Memberships & Accreditations
      </h1>

      <Marquee
        direction="left"
        speed={100}
        delay={5}
        pauseOnHover
        class="mb-20 mt-5 px-4"
      >
        <div className="mx-12 h-1/3 w-1/3">
          <img src="./assets/fiat.png" />
        </div>
        <div className="mx-10 -mr-6 h-1/3 w-1/3">
          <img src="./assets/effssa.png" />
        </div>
        <div className="mx-10 -ml-16 h-1/3 w-1/3">
          <img src="./assets/addis.png" />
        </div>
        <div className="mx-24 h-1/3 w-1/3">
          <img src="./assets/aa.png" />
        </div>
        <div className="mx-24 h-1/3 w-1/3">
          <img src="./assets/emaa.png" />
        </div>
        {/* <div className="mx-24">
          <img src="./assets/castle.png" />
        </div> */}
      </Marquee>
      {/* 
      <h1 class="text-center text-5xl font-bold text-[#247C8F] mt-8 mb-8 px-4">
        We are Certified
      </h1>

      <Marquee
        direction="right"
        speed={100}
        delay={5}
        pauseOnHover
        class="mb-14 px-4"
      >
        <div className="mx-16">
          <img src="./assets/kobil.png" />
        </div>
        <div className="mx-24">
          <img src="./assets/castle.png" />
        </div>
        <div className="mx-24">
          <img src="./assets/kobil.png" />
        </div>
        <div className="mx-24">
          <img src="./assets/castle.png" />
        </div>
        <div className="mx-24">
          <img src="./assets/kobil.png" />
        </div>
        <div className="mx-24">
          <img src="./assets/castle.png" />
        </div>
      </Marquee> */}
      <div className="mt-12"></div>
    </>
  );
};

export default Slider;
