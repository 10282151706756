import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://shipment-management-gwbb.onrender.com/api/v1";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.userToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  endpoints: (build) => ({
    getUserDetails: build.query({
      query: () => ({
        url: "auth/profile",
        method: "GET",
      }),
    }),

    fetchShipment: build.query({
      query: () => ({
        url: "bol", // Adjust the URL as per your API
        method: "GET",
      }),
    }),
    fetchShipmentByTrackingId: build.query({
      query: (trackingId) => ({
        url: `bol/${trackingId}`, // Adjust the URL as per your API
        method: "GET",
      }),
    }),
    fetchShipmentByContainerId: build.query({
      query: (containerId) => ({
        url: `shipment/by-container-number/${containerId}`, // Adjust the URL as per your API
        method: "GET",
      }),
    }),
    requestQuote: build.mutation({
      query: (requestData) => ({
        url: "quote",
        method: "POST",
        body: requestData,
      }),
    }),
    getAllQuotes: build.query({
      query: ({ page, limit }) => ({
        url: `quote?page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
  }),
});

// export react hook
export const {
  useGetUserDetailsQuery,
  useFetchShipmentQuery,
  useFetchShipmentByTrackingIdQuery,
  useRequestQuoteMutation,
  useGetAllQuotesQuery,
} = authApi;
