import React from "react";
import Banner from "../Reusable/Banner";
import Container from "../Reusable/Container";
import OurServices from "./OurServices";

const OceanFreight = () => {
  return (
    <div>
     
      <h1 className=" text-center text-5xl pt-20 pb-12 px-4 font-bold text-[#247C8F] ">
        Our Services
      </h1>
      <Container>
        <div className="  max-w-[1240px] mx-auto grid md:grid-cols-2 gap-5 pb-24 md:py-24 my-12 md:my-0">
          <div className="flex flex-col">
              <h1 className="text-xl mt-2 mb-2 px-4 font-bold text-[#247b8fb9] max-w-2xl">
                Ocean Freight
              </h1>

              <p className="text-lg mt-8 text-[#919B9D] max-w-2xl text-start mx-4">
                Our ocean freight services offer reliable shipping solutions for your cargo
                needs across the globe. With extensive experience and a robust network of
                partners, we ensure your shipments reach their destination safely and on
                time.
              </p>
              
              <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                Whether you're importing or exporting goods, our dedicated team handles
                every aspect of the ocean freight process. From container loading and
                unloading to customs clearance, we provide comprehensive logistics support
                tailored to your requirements.
              </p>

              <div className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                <li>Efficient and cost-effective shipping solutions</li>
                <li>Global network ensuring extensive reach and coverage</li>
                <li>Customized logistics planning for diverse cargo types</li>
                <li>Real-time tracking and status updates for your shipments</li>
                <li>Professional handling of documentation and compliance</li>
                <li>Competitive pricing with transparent cost structures</li>
                <li>Responsive customer service to address all inquiries</li>
              </div>

              <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                Whether you require full-container loads (FCL) or less-than-container
                loads (LCL), our ocean freight services ensure reliable and efficient
                transportation solutions. Contact us today to discuss your shipping needs
                and benefit from our expertise in global logistics.
              </p>
            </div>

          <div className=" flex justify-center w-auto p-8 md:p-0">
            <img className=" w-full rounded-md" src="../assets/Ocean.jfif" alt="Ocean Cargo image" />
          </div>
        </div>
      </Container>

      <OurServices />
    </div>
  );
};

export default OceanFreight;
