import React from "react";
import Banner from "../Reusable/Banner";
import Container from "../Reusable/Container";
import OurServices from "./OurServices";

const SpecialCargo = () => {
  return (
    <div>
     
      <h1 className=" text-center text-5xl pt-20 pb-12 px-4 font-bold text-[#247C8F] ">
        Our Services
      </h1>
      <Container>
        <div className="  max-w-[1240px] mx-auto grid md:grid-cols-2 gap-5 pb-24 md:py-24 my-12 md:my-0">
          <div className="flex flex-col">
              <h1 className="text-xl mt-2 mb-2 px-4 font-bold text-[#247b8fb9] max-w-2xl">
                Special Cargo Handling
              </h1>

              <p className="text-lg mt-8 text-[#919B9D] max-w-2xl text-start mx-4">
                Our special cargo handling services are designed to manage unique and
                sensitive shipments with precision and care. Whether it's oversized
                equipment, hazardous materials, or perishable goods, we specialize in
                ensuring safe and efficient transportation from origin to destination.
              </p>
              
              <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                With a team of trained professionals and specialized equipment, we handle
                every aspect of special cargo logistics. From packaging and labeling to
                regulatory compliance and transport logistics, we prioritize the integrity
                and timely delivery of your valuable cargo.
              </p>

              <div className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                <li>Expert handling of oversized and heavy shipments</li>
                <li>Specialized equipment and secure transport solutions</li>
                <li>Compliance with international regulations and safety standards</li>
                <li>Customized logistics planning for unique cargo requirements</li>
                {/* <li>24/7 monitoring and support for sensitive shipments</li> */}
                <li>Emergency response capabilities and risk mitigation strategies</li>
                <li>Transparent communication and real-time shipment tracking</li>
              </div>

              <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                Whether you're transporting high-value goods or delicate equipment, our
                special cargo handling services ensure reliable and secure logistics
                solutions. Contact us today to discuss your specialized cargo needs and
                benefit from our expertise in handling challenging shipments.
              </p>
            </div>

          <div className=" flex justify-center w-auto p-8 md:p-0">
            <img className=" w-full rounded-md" src="../assets/SpecialCargo.jfif" alt="Specail Cargo Handling Image" />
          </div>
        </div>
      </Container>

      <OurServices />
    </div>
  );
};

export default SpecialCargo;
