import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./Pages/AboutUs";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import Location from "./Pages/Location";
import Contact from "./Pages/Contact";
import Login from "./Components/Homepage/Login";
import Layout from "./Components/Layout/Layout";
import AdminDashboard from "./Pages/AdminDashboard";
import User from "./Pages/User";
import DetailPage from "./Components/User/DetailPage";

import ServiceDetail from "./Components/Services/ServiceDetail";

function App() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/user" element={<User />} />
          <Route path="/user/:id" element={<DetailPage />} />
          <Route path="/services/:service" element={<ServiceDetail />} />
        </Route>

        <Route path="/admin" element={<AdminDashboard />} />
      </Routes>
    </div>
  );
}

export default App;
