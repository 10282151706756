import React from "react";
import Banner from "../Reusable/Banner";
import Container from "../Reusable/Container";
import OurServices from "./OurServices";

const AirFreight = () => {
  return (
    <div>
     
      <h1 className=" text-center text-5xl pt-20 pb-12 px-4 font-bold text-[#247C8F] ">
        Our Services
      </h1>
      <Container>
        <div className="  max-w-[1240px] mx-auto grid md:grid-cols-2 gap-5 pb-24 md:py-24 my-12 md:my-0">
          <div className="flex flex-col">
              <h1 className="text-xl mt-2 mb-2 px-4 font-bold text-[#247b8fb9] max-w-2xl">
                Air Freight
              </h1>

              <p className="text-lg mt-8 text-[#919B9D] max-w-2xl text-start mx-4">
                Air freight services provide reliable transportation solutions for your
                cargo needs. Whether you're shipping domestically or internationally, we
                ensure efficient and secure delivery to your destination.
              </p>
              
              <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                Our team handles every aspect of the air freight process, from packaging
                and documentation to tracking and delivery confirmation. We prioritize
                safety, speed, and reliability to meet your logistics requirements
                effectively.
              </p>

              <div className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                <li>Fast and secure transportation solutions</li>
                <li>Customized logistics planning for every shipment</li>
                <li>Efficient handling of customs procedures</li>
                <li>Real-time tracking and updates on your cargo</li>
                <li>Dedicated customer support for all inquiries</li>
                <li>Competitive rates for domestic and international shipments</li>
                <li>Flexible scheduling options to meet your timeline</li>
              </div>

              <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                Whether you're shipping perishable goods, high-value items, or urgent
                packages, our air freight services ensure timely and secure delivery
                worldwide. Contact us today to discuss your shipping needs!
              </p>
            </div>

          <div className=" flex justify-center w-auto p-8 md:p-0 rounded-xl">
            <img className=" w-full rounded-md" src="../assets/Air.jfif" alt="air" />
          </div>
        </div>
      </Container>

      <OurServices />
    </div>
  );
};

export default AirFreight;
