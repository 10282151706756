import React from "react";
import Banner from "../Reusable/Banner";
import Container from "../Reusable/Container";
import OurServices from "./OurServices";

const LogisticsConsultancy = () => {
  return (
    <div>
      
      <h1 className=" text-center text-5xl pt-20 pb-12 px-4 font-bold text-[#247C8F] ">
        Our Services
      </h1>
      <Container>
        <div className="  max-w-[1240px] mx-auto grid md:grid-cols-2 gap-5 pb-24 md:py-24 my-12 md:my-0">
          <div className="flex flex-col">
              <h1 className="text-xl mt-2 mb-2 px-4 font-bold text-[#247b8fb9] max-w-2xl">
                Logistics Consultancy
              </h1>

              <p className="text-lg mt-8 text-[#919B9D] max-w-2xl text-start mx-4">
                Our logistics consultancy services provide strategic advice and expertise
                to optimize your supply chain operations. Whether you're looking to
                streamline processes, reduce costs, or enhance efficiency, our team of
                experts is here to support your logistics goals.
              </p>
              
              <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                With a focus on delivering measurable results, we offer customized
                solutions tailored to your business needs. From inventory management and
                warehouse optimization to transportation planning and logistics
                technology, we provide comprehensive consultancy services to drive
                success.
              </p>

              <div className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                <li>Strategic guidance to enhance supply chain efficiency</li>
                <li>Cost-effective solutions to improve logistics performance</li>
                <li>Implementation of best practices for sustainable operations</li>
                <li>Advanced analytics and data-driven insights for informed decisions</li>
                <li>Training and support to empower your logistics team</li>
                <li>Continuous evaluation and optimization of logistics strategies</li>
                <li>Personalized approach to address specific business challenges</li>
              </div>

              <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                Whether you're a small business or a large enterprise, our logistics
                consultancy services are designed to maximize efficiency and profitability
                across your supply chain. Contact us today to explore how we can help
                transform your logistics operations and achieve sustainable growth.
              </p>
            </div>

          <div className=" flex justify-center w-auto p-8 md:p-0">
            <img
              className=" w-full"
              src="../assets/LogisticsConsultancy.jfif"
              alt="Logistics Image"
            />
          </div>
        </div>
      </Container>

      <OurServices />
    </div>
  );
};

export default LogisticsConsultancy;
