// import log from "../../assets/login.png";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { userLogin } from "../../store/actions/auth.action";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../spinner/spinner";
import { hover } from "@testing-library/user-event/dist/hover";

const Login = () => {
  const { loading, userInfo, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usertoken = localStorage.getItem("userToken");
  useEffect(() => {
    if (userInfo && usertoken ) {
      navigate("/user");
    }
  }, [ userInfo]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get("email");
    const password = data.get("password");

    return dispatch(userLogin({ email, password }));
  };

  return (
    <div className="flex gap-4 w-screen h-screen overflow-hidden">
      <div className="hidden md:flex flex-1">
        <img
          src="./assets/login.png"
          alt="Logo"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="bg-white flex-1 mb-20 flex justify-center items-center">
        <div className="flex">
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                component="h1"
                variant="h4"
                className=" font-semibold"
              >
                Log in
              </Typography>

              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}
                {error && (
                  <Typography
                    sx={{
                    
                      mt: 1,
                      mb:1,
                    }}
                    className="font-medium"
                    color="error"
                  >
                    {error}
                  </Typography>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: "#247C8F",
                    "&:hover": {
                      backgroundColor: "#005B6E", // New background color on hover
                    },
                    mt: 3,
                    mb: 2,
                  }}
                  disabled={loading}
                >
                  {loading ? <Spinner /> : "Login"}
                </Button>
                {/* <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid> */}
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Login;
