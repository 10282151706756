import React from "react";
import Container from "../Reusable/Container";
import { Button } from "@mui/material";
import Counter from "react-number-increment";

import { motion } from "framer-motion";
import { fadeIn } from "../Reusable/Variants";

const Results = () => {
  return (
    <Container>
      <div className="flex-col py-2 md:py-24">
        {/* <div className="flex justify-center">
          <h1 className="text-3xl pt-20 pb-10 font-bold text-[#247C8F] max-w-2xl  ">
            Our results in numbers
          </h1>
        </div> */}
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-10">
          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className=" flex flex-col items-center  "
          >
            <h1 className="text-3xl py-5 font-bold text-[#65B8F3] max-w-2xl">
              <Counter
                start={0} // starting
                end={99} // ends
                speed={0.25} // 100ms
                // in case if you want to decrement
                // add custom style
                threshold={1} // viewport 1=100%,
                child="%" // add childs ie + , - , *
                offset={2} // increments by 5
                seperate="," // seperates number in comma
              />
            </h1>
            <h1 className="text-xl py-2 font-bold text-[#247C8F] max-w-2xl">
              Customer satisfaction
            </h1>
            <p className="text-sm py-2  text-[#919B9D] max-w-2xl text-center mx-4">
            Over 98% our client testify to our commitment, ensuring every journey is meticulously handled. Experience unparalleled excellence with Input Africa Today
{" "}
            </p>
        
          </motion.div>

          <motion.div
            variants={fadeIn("up", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className=" flex flex-col items-center  "
          >
            <h1 className="text-3xl py-5 font-bold text-[#65B8F3] max-w-2xl">
              <Counter
                start={0} // starting
                end={2000} // ends
                // 100ms
                // in case if you want to decrement
                // add custom style
                threshold={1} // viewport 1=100%,
                child="+" // add childs ie + , - , *
                offset={200} // increments by 5
                seperate="," // seperates number in comma
              />
            </h1>
            <h1 className="text-xl py-2 font-bold text-[#247C8F] max-w-2xl">
              Active Users
            </h1>
            <p className="text-sm py-2  text-[#919B9D] max-w-2xl text-center mx-4">
            With over 2k Active Users, We ensure every interaction is seamlessly managed.
{" "}
            </p>
          
          </motion.div>

          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className=" flex flex-col items-center  "
          >
            <h1 className="text-3xl py-5 font-bold text-[#65B8F3] max-w-2xl">
              <Counter
                start={0} // starting
                end={250} // ends
                speed={0.25} // 100ms
                // in case if you want to decrement
                // add custom style
                threshold={1} // viewport 1=100%,
                child="%" // add childs ie + , - , *
                offset={5} // increments by 5
                seperate="," // seperates number in comma
              />
            </h1>
            <h1 className="text-xl py-2 font-bold text-[#247C8F] max-w-2xl">
              Company Growth
            </h1>
            <p className="text-sm py-2  text-[#919B9D] max-w-2xl text-center mx-4">
            Experiencing exponential growth each year, we continue to expand our horizons. Partner with Input Africa today and be part of our thriving journey towards logistics excellence.{" "}
            </p>

            
          </motion.div>
        </div>
      </div>
    </Container>
  );
};

export default Results;
