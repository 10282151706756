import React from "react";
import Banner from "../Reusable/Banner";
import Container from "../Reusable/Container";
import OurServices from "./OurServices";

const WarehouseDistribution = () => {
  return (
    <div>
      <h1 className=" text-center text-5xl pt-20 pb-12 px-4 font-bold text-[#247C8F] ">
        Our Services
      </h1>
      <Container>
        <div className="  max-w-[1240px] mx-auto grid md:grid-cols-2 gap-5 pb-24 md:py-24 my-12 md:my-0">
          <div className="flex flex-col">
              <h1 className="text-xl mt-2 mb-2 px-4 font-bold text-[#247b8fb9] max-w-2xl">
                Warehouse and Distribution 
              </h1>

              <p className="text-lg mt-8 text-[#919B9D] max-w-2xl text-start mx-4">
                Our warehouse and distribution services are designed to optimize your
                supply chain management with seamless logistics solutions. We offer
                state-of-the-art warehouse facilities and efficient distribution networks
                to meet your storage and transportation needs.
              </p>
              
              <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                From inventory management to order fulfillment, our comprehensive services
                ensure your products are handled with care and delivered to customers
                reliably and on time. We leverage advanced technology and industry best
                practices to streamline operations and enhance efficiency across the
                supply chain.
              </p>

              <div className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                <li>Secure and spacious warehouse facilities</li>
                <li>Customized storage solutions tailored to your inventory needs</li>
                <li>Efficient pick-and-pack services for accurate order processing</li>
                <li>Real-time inventory tracking and management systems</li>
                <li>Responsive distribution networks for fast and reliable delivery</li>
                <li>Compliance with safety regulations and quality standards</li>
                <li>Continuous improvement initiatives for operational excellence</li>
              </div>

              <p className="text-lg mt-4 text-[#919B9D] max-w-2xl text-start mx-4">
                Whether you're managing e-commerce fulfillment or global distribution, our
                warehouse and distribution services ensure superior logistics support. Contact
                us today to discuss how we can optimize your supply chain operations and
                drive business growth.
              </p>
            </div>

          <div className=" flex justify-center w-auto p-8 md:p-0">
            <img className=" w-full rounded-md" src="../assets/Warehouse.jfif" alt="Warehouse Distribution Image" />
          </div>
        </div>
      </Container>

      <OurServices />
    </div>
  );
};

export default WarehouseDistribution;
