import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const backendURL = "https://shipment-management-gwbb.onrender.com/api/v1";

export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${backendURL}/auth/login`,
        { email: email, password },
        config
      );

      // store user's token in local storage
      localStorage.setItem("userToken", data.accessToken);

      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const userLogout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      // Remove user token from localStorage
      localStorage.removeItem("userToken");
      return {}; // Return an empty object as the action payload
    } catch (error) {
      // Return error message if an error occurs
      return rejectWithValue(error.message);
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/register",
  async ({ firstName, email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post(
        `${backendURL}/api/user/register`,
        { firstName, email, password },
        config
      );
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);
export const fetchShipment = createAsyncThunk(
  "auth/fetchShipment",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        throw new Error("User token not found.");
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${backendURL}/bol/own/?page=${page}&limit=${limit}`, // Use page and limit parameters
        config
      );

      return data;
    } catch (error) {
      if (error.response) {
        // Return specific status code errors
        if (error.response.status === 401 || error.response.status === 403) {
          return rejectWithValue({
            message: "Unauthorized access",
            status: error.response.status,
          });
        }
      }
      return rejectWithValue({
        message: error.response?.data?.message || error.message,
        status: error.response?.status,
      });
    }
  }
);
// export const fetchShipment = createAsyncThunk(
//   "auth/fetchShipment",
//   async ({ page, limit }, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("userToken");
//       if (!token) {
//         throw new Error("User token not found.");
//       }

//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       };

//       const { data } = await axios.get(
//         `${backendURL}/bol/own/?page=${page}&limit=${limit}`, // Use page and limit parameters
//         config
//       );

//       return data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || error.message);
//     }
//   }
// );

export const fetchShipmentByTrackingId = createAsyncThunk(
  "auth/fetchShipmentByTrackingId",
  async (trackingId, { rejectWithValue }) => {
    try {
      
      const { data } = await axios.get(
        `${backendURL}/bol/by-bol-number/${trackingId}` // Adjust the URL endpoint according to your API
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchShipmentByContainerId = createAsyncThunk(
  "auth/fetchShipmentByContainerId",
  async (containerId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}/shipment/by-container-number/${containerId}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const requestQuote = createAsyncThunk(
  "quote/requestQuote",
  async (requestData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        throw new Error("User token not found.");
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      // Modify the request data according to the required format
      const modifiedRequestData = {
        fullName: requestData.fullName,
        email: requestData.email,
        phone: requestData.phone,
        originCountry: requestData.originCountry,
        destinationCountry: requestData.destinationCountry,
        comment: requestData.comment,
      };

      const { data } = await axios.post(
        `${backendURL}/quote`,
        modifiedRequestData,
        config
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);


export const getAllQuotes = createAsyncThunk(
  "quote/getAllQuotes",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}/quote?page=${page}&limit=${limit}`
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);
